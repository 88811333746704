import React from "react";
import logoHome from "../../../assets/Logos/logoHome.png";
import imageHome from "../../../assets/Logos/imageHome.png";
import styles from "./primarySection.module.scss";
import { useMyContext } from "../../../Provider/Provider";
import { useNavigate } from "react-router-dom";

const PrimarySection = () => {
  const { user, handleLogin } = useMyContext();
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <img className={styles.logoHome} src={logoHome} alt="logoHome" />
        <div className={styles.textContainer}>
          <div className={styles.title}>
            Conocé nuestros Test de Orientación Vocacional
          </div>
          <div className={styles.subTitle}>
            Descubrí cuales son tus pasiones y que profesiones se adhecuan más a
            tu estilo de vida.
          </div>
        </div>
        <div
          className={styles.botonStart}
          onClick={() => {
            user
              ? navigate("/main-Test-Orientacion-Vocacional")
              : handleLogin();
          }}
        >
          Probar TOV
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={imageHome} alt="imageHome" className={styles.imageHome} />
        <div className={styles.textImage}>
          Tu plataforma favorita para conocer a fondo tus gustos y aspiraciones.
          Encontrá aquello que te apasiona con todos los Test que tenemos para
          vos.
        </div>
      </div>
    </div>
  );
};

export default PrimarySection;
