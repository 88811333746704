import React, { FC } from "react";
import { MercadoPagoPayment } from "./MercadoPagoPayment";
import { FormPremium } from "./FormPremium";
import { PropsUserData } from "../../../utils/interfaces";
import { useSearchParams } from "react-router-dom";

interface PropsActivatePremium {
  userData: PropsUserData | undefined;
}
const ActivatePremium: FC<PropsActivatePremium> = ({ userData }) => {
  let [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  return userData?.isPayment ||
    status === "success" ||
    status === "approved" ? (
    <FormPremium />
  ) : (
    <MercadoPagoPayment />
  );
};

export default ActivatePremium;
