import React, { FC } from "react";
import styles from "./containerAlumn.module.scss";
import { PropsMessage } from "../../utils";
import { useMyContext } from "../../../../../Provider/Provider";

interface PropsContainerAlumn {
  message: PropsMessage;
}
const ContainerAlumn: FC<PropsContainerAlumn> = ({ message }) => {
  const { user } = useMyContext();
  return (
    <div className={styles.container}>
      <div className={styles.containerAlumn}>
        <div className={styles.containerTextAi}>
          <div className={styles.textAI}>{message.message}</div>
        </div>
        <img
          src={user?.photoProfile || "default-profile-image-url"}
          alt="Profile"
          className={styles.circleImg}
        />
      </div>
    </div>
  );
};

export default ContainerAlumn;
