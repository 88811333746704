import React from "react";
import styles from "./resultCalendar.module.scss";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../../../Provider/Provider";

const ResultCalendar = () => {
  const navigate = useNavigate();
  const { user } = useMyContext();
  return (
    <div className={styles.container}>
      <img
        src={user?.photoProfile || "default-profile-image-url"}
        alt="Profile"
        className={styles.imageProfile}
      />
      <div className={styles.containterTitle}>
        <div className={styles.title}>¡FELICITACIONES {user?.firstName}!</div>
        <div className={styles.subTitle}>YA AGENDASTE TU ENTREVISTA</div>
      </div>
      <div className={styles.description}>
        ¡Nos vemos muy pronto!
        <br></br>
        <br></br>
        Revisá tu mail para estar atenta a todas nuestras indicaciones previas.
        Te enviaremos información adicional del profesional seleccionado.
      </div>
      <div
        className={styles.buttomHome}
        onClick={() => navigate("/main-Test-Orientacion-Vocacional")}
      >
        Volver al inicio
      </div>
    </div>
  );
};

export default ResultCalendar;
