import React, { useEffect, useState } from "react";
import { ResultMMYMG } from "./ResultMMYMG";
import { QuestionsMMYMG } from "./QuestionsMMYMG";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../..";
import { useMyContext } from "../../Provider/Provider";
import { Loader } from "../Shared/Loader";
import { PropsUserData } from "../../utils/interfaces";

const TestMMYMG = () => {
  const [userData, setUserData] = useState<PropsUserData>();
  const { user, refresh } = useMyContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (user) {
        try {
          const userRef = doc(db, "Users", user.id);
          const docSnap = await getDoc(userRef);

          if (docSnap.data()?.firstAreaMMYMG) {
            setUserData(docSnap.data() as PropsUserData);
          }
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
      setIsLoading(false);
    })();
  }, [user, refresh]);

  return isLoading ? (
    <Loader />
  ) : userData?.firstAreaMMYMG ? (
    <ResultMMYMG userData={userData} />
  ) : (
    <QuestionsMMYMG />
  );
};

export default TestMMYMG;
