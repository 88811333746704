import React from "react";
import { Home } from "../../components/Home";
import { useMyContext } from "../../Provider/Provider";
import { Navigate } from "react-router-dom";

const HomePage = () => {
  const { user } = useMyContext();
  if (user?.email === "fran_olivero_sag@hotmail.com") {
    return <Navigate to="/teacher" replace />;
  } else if (user) {
    return <Navigate to="/main-Test-Orientacion-Vocacional" replace />;
  }
  return <Home />;
};

export default HomePage;
