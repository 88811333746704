import React from "react";
import styles from "./containerProfile.module.scss";
import { useMyContext } from "../../../Provider/Provider";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/Icons/arrowBack.png";
import isPremium from "../../../assets/components/profile/isPremium.png";
import { useLocation } from "react-router-dom";
import { PropsUserData } from "../../../utils/interfaces";
interface ProsContaienrProfile {
  title: string;
  userData?: PropsUserData;
}
const ContainerProfile = ({ title, userData }: ProsContaienrProfile) => {
  const { user } = useMyContext();
  const navigate = useNavigate();
  let location = useLocation();

  return (
    <div className={styles.containerProfile}>
      <img
        src={user?.photoProfile || "default-profile-image-url"}
        alt="Profile"
        className={styles.imageProfile}
      />
      <div className={styles.profileText}>
        <div className={styles.containerTitle}>
          {location.pathname !== "/main-Test-Orientacion-Vocacional" ? (
            <img
              src={arrowBack}
              alt="Back"
              onClick={() => {
                navigate(-1);
              }}
            />
          ) : (
            <></>
          )}
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.name}>
          {user?.firstName} {user?.secondName}{" "}
          {userData?.isPremium ? (
            <img src={isPremium} alt="isPremium" className={styles.crown} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContainerProfile;
