import React, { FC } from "react";
import styles from "./bar.module.scss";

interface PropsBar {
  totalStep: number;
}

const Barcom: FC<PropsBar> = ({ totalStep }) => {
  const getItemStyle = (porcent: number): React.CSSProperties => ({
    height: "7px",
    width: `${porcent}%`,
    backgroundColor: "#497078",
    borderRadius: "2px",
    textAlign: "center",
    lineHeight: "20px",
  });

  return (
    <div className={styles.container}>
      <div style={getItemStyle(totalStep)}> </div>
    </div>
  );
};

export default Barcom;
