import React, { useEffect, useState } from "react";
import { ResultCincoGrandes } from "./ResultCincoGrandes";
import { QuestionsCincoGrande } from "./QuestionsCincoGrandes/QuestionsCincoGrande";
import { useMyContext } from "../../Provider/Provider";
import { doc, getDoc } from "firebase/firestore";

import { db } from "../..";
import { Loader } from "../Shared/Loader";
import { PropsUserData } from "../../utils/interfaces";
const TestCincoGrandes = () => {
  const { user, refresh } = useMyContext();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<PropsUserData>();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (user) {
        try {
          const userRef = doc(db, "Users", user.id);
          const docSnap = await getDoc(userRef);

          if (docSnap.data()?.A) {
            setUserData(docSnap.data() as PropsUserData);
          }
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
      setIsLoading(false);
    })();
  }, [user, refresh]);

  return isLoading ? (
    <Loader />
  ) : userData?.A ? (
    <ResultCincoGrandes userData={userData} />
  ) : (
    <QuestionsCincoGrande />
  );
};

export default TestCincoGrandes;
