import styles from "./caledar.module.scss";
import candely from "../../assets/pages/calendar/candely.png";
import { db } from "../..";
import { Loader } from "../Shared/Loader";
import { TitleBack } from "../Shared/TitleBack";
import { useMyContext } from "../../Provider/Provider";
import { PropsUserData } from "../../utils/interfaces";
import { ResultCalendar } from "./ResultCalendar";
import { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { PopupButton, useCalendlyEventListener } from "react-calendly";

const Calendar = () => {
  const { user } = useMyContext();
  const [refresh, setRefresh] = useState(false);
  const [userData, setUserData] = useState<PropsUserData>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveEvent = async (event: string) => {
    if (user) {
      try {
        const userRef = doc(db, "Users", user.id);
        const docSnap = await getDoc(userRef);
        if (docSnap.data()) {
          await setDoc(userRef, { scheduler: event }, { merge: true });
        }
        setRefresh(!refresh);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  useCalendlyEventListener({
    onEventScheduled: (e) => handleSaveEvent(e.data.payload.event.uri),
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (user) {
        try {
          const userRef = doc(db, "Users", user.id);
          const docSnap = await getDoc(userRef);
          docSnap.data();
          setUserData(docSnap.data() as PropsUserData);
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
      setIsLoading(false);
    })();
  }, [user, refresh]);

  return isLoading ? (
    <Loader />
  ) : userData?.scheduler ? (
    <ResultCalendar />
  ) : (
    <div className={styles.container}>
      <TitleBack title="ENTREVISTA" />
      <div className={styles.description}>
        ¡Falta poco para que conozcas todos tus resultados!
        <br></br>
        <br></br>
        Durante la entrevista podrás interactuar directamente con uno de
        nuestros profesionales, donde a través de actividades y otros recursos
        lograremos compartirte un analisis aun más personalizado.
        <br></br>
        <br></br>
        Conocé nuestras fechas dsponibles para que puedas agendar la entrevista
        que mejor se adapte a tus horarios. Una vez agendado te enviaremos un
        email con todos los detalles a tener en cuenta previo a la entrevista y
        te brindaremos info extra sobre nuestros profesionales.
      </div>
      <div className={styles.containerContent}>
        <div className={styles.box}>
          <img src={candely} alt="" />
          <div className={styles.textBox}>
            Gestionamos todas nuestras entrevistas con “Calendly”
          </div>
          <PopupButton
            url="https://calendly.com/empresatov/testtov"
            rootElement={document.body}
            className={styles.buttomCandely}
            prefill={{
              email: user?.email ?? "",
              firstName: user?.firstName ?? "",
              lastName: user?.secondName,

              smsReminderNumber: "+5493229544527",
            }}
            pageSettings={{
              backgroundColor: "ffffff",
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: "00a2ff",
              textColor: "4d5055",
            }}
            text="Agendar"
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
