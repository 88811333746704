import { Routes, Route, Navigate } from "react-router-dom";
import { useMyContext } from "../Provider/Provider";
import {
  CalendarPage,
  HomePage,
  MainPage,
  PanelTeacher,
  PremiumPage,
  PrivacityPage,
  ResumeAIPage,
  TermsPage,
  TestChasidePage,
  TestCincoGrandesPage,
  TestMMPage,
  TestResultsPage,
} from "../pages";
import { IsMobilePage } from "../pages/IsMobile";

const TovRoutes = () => {
  const { user } = useMyContext();
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {!user ? (
        <>
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      ) : user.email === "fran_olivero_sag@hotmail.com" ? (
        <Route path="/teacher" element={<PanelTeacher />} />
      ) : (
        <>
          <Route
            path="*"
            element={
              <Navigate to="/main-Test-Orientacion-Vocacional" replace />
            }
          />
          <Route
            path="/main-Test-Orientacion-Vocacional"
            element={<MainPage />}
          />
          <Route path="/test-chaside" element={<TestChasidePage />} />
          <Route
            path="/test-cinco-grandes"
            element={<TestCincoGrandesPage />}
          />
          <Route path="/test-MM-y-MG" element={<TestMMPage />} />
          <Route path="/terminos-y-condiciones" element={<TermsPage />} />
          <Route path="/politicas-de-privacidad" element={<PrivacityPage />} />
          <Route path="/testResults" element={<TestResultsPage />} />
          <Route path="/resumeAI" element={<ResumeAIPage />} />
          <Route path="/premium" element={<PremiumPage />} />
        </>
      )}
      <Route path="/isMobile" element={<IsMobilePage />} />
      <Route path="/calendar" element={<CalendarPage />} />
    </Routes>
  );
};

export default TovRoutes;
