import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import { Layout } from "./components/Layout";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from ".";
import { useMyContext } from "./Provider/Provider";
import { PropsUserData } from "./utils/interfaces";

function App() {
  const { setUser } = useMyContext();
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, async (userGoole) => {
      if (userGoole) {
        try {
          const userDoc = doc(db, "Users", userGoole.uid);
          const docSnap = await getDoc(userDoc);
          let parts = userGoole.displayName?.split(" "); // Esto divide el string en ["Francisco", "Olivero"]
          let firstName = parts && parts[0]; // "Francisco"
          let lastName = parts && parts[1];

          const newUser = {
            firstName: firstName,
            secondName: lastName,
            email: userGoole.email,
            photoProfile: userGoole?.photoURL,
            id: userGoole.uid,
          };
          if (!docSnap.data()) {
            await setDoc(doc(db, "Users", userGoole.uid), newUser);
          }
          setUser(newUser as PropsUserData);
          localStorage.setItem("user", JSON.stringify(newUser));
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      } else {
        setUser(null);
        localStorage.setItem("user", "");
      }
    });
  }, [auth, setUser]);
  return (
    <BrowserRouter>
      <Layout>
        <Routes />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
