import React, { FC } from "react";
import styles from "./infoMMYMG.module.scss";
import arrowBack from "../../../../assets/Icons/arrowBack.png";
import { useNavigate } from "react-router-dom";
interface PropsInfo {
  setInfoMMYMGState: (e: boolean) => void;
}

const InfoMMYMG: FC<PropsInfo> = ({ setInfoMMYMGState }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>
        <div className={styles.containerTitle}>
          <img
            src={arrowBack}
            alt="Back"
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className={styles.title}>TEST MM & MG</div>
        </div>
        <div className={styles.boxHeader}>SOBRE LA EVALUACIÓN</div>
      </div>
      <div className={styles.textInfo}>
        El segundo test consta de un total de 80 preguntas que deberán ser
        respondidas con “ME INTERESA” o con “NO ME INTERESA” dependiendo como te
        veas frente a diversas situaciones. Una vez seleccionada la respuesta,
        las preguntas se irán pasando automáticamente. No existen respuestas
        correctas o incorrectas; lo importante es que contestes con sinceridad y
        confianza para que puedas conocer mejor tus intereses vocacionales. De
        acuerdo a tus respuestas, obtendrás un resultado con las dos categorías
        o áreas de conocimiento que más se adapten a tí.
      </div>
      <div className={styles.containerButton}>
        <div
          className={styles.buttomQuestion}
          onClick={() => {
            setInfoMMYMGState(true);
          }}
        >
          Entendido
        </div>
      </div>
    </div>
  );
};

export default InfoMMYMG;
