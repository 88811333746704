import styles from "./isMobile.module.scss";
import cross from "../../assets/components/header/cross.png";
import logoHome from "../../assets/Logos/logoHome.png";
import { useNavigate } from "react-router-dom";
const IsMobilePage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.containerMobile}>
      <div className={styles.boxAccount}>
        <div className={styles.containerCross}>
          <img
            src={cross}
            alt="cross"
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>
        <img className={styles.logoHome} src={logoHome} alt="logoHome" />
        <div className={styles.description}>
          Accedé a nuestra plataforma web para completar todas las fases de tu
          proceso de evaluación de manera óptima.
          <br></br>
          Desde la comodidad de tu computadora podrás finalizar los test,
          participar de las distintas actividades interactivas y realizar
          entrevistas personalizadas con nuestros expertos.
          <br></br>Asegurate de aprovechar todas las herramientas y recursos que
          ofrecemos para maximizar tu rendimiento y obtener resultados precisios
          y personalizados.
        </div>
      </div>
    </div>
  );
};

export default IsMobilePage;
