import React, { FC, useEffect, useRef } from "react";
import styles from "./messageList.module.scss";
import { PropsMessage } from "../utils";
import ContainerAlumn from "./ContainerAlumn/ContainerAlumn";
import { ContainerTovy } from "./ContainerTovy";

interface PropsMessageList {
  isTyping: boolean;
  messages: PropsMessage[];
}
const MessageList: FC<PropsMessageList> = ({ isTyping, messages }) => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = scrollableDivRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  }, [messages]);
  return (
    <div className={styles.container} ref={scrollableDivRef}>
      <div className={styles.containerMessages}>
        {messages.map((message, index) =>
          message.sender === "tovy" ? (
            <ContainerTovy key={index} message={message} />
          ) : (
            <ContainerAlumn key={index} message={message} />
          )
        )}
      </div>
      <div className={styles.containerLoading}>
        {isTyping && (
          <>
            <div className={styles.loader}></div> Tovy esta escribiendo
          </>
        )}
      </div>
    </div>
  );
};

export default MessageList;
