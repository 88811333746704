import React from "react";
import imageChoose from "../../../assets/Logos/imageChoose.png";
import styles from "./thirdSection.module.scss";

const ThridSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={imageChoose} alt="imageHome" className={styles.imageHome} />
        <div className={styles.textImage}>
          <div>Maria Laura, Argentina.</div>
          <div>
            “Encantada de poder trabajar de lo que más me gusta gracias a TOV”
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.title}>¿Por qué nos eligen?</div>
        <div className={styles.subTitle}>
          <div>
            Día a día ofrecemos un servicio pensado para que puedas
            desarrollarte de manera eficaz en un ambiente que se asemeje a tus
            habilidades y valores.
          </div>
          <div>
            Proponemos distintas etapas en las cuales podrás conocer muchos
            aspectos de tu personalidad y a su vez conseguirás un analisis de
            tus atributos y nuestras recomendaciones.
          </div>
          <div>
            Tendrás la posibilidad de contactar a profecionales de manera
            personalizada para mejorar tu enfoque hacia el futuro.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThridSection;
