import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MyContextProvider } from "./Provider/Provider";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB2db5nqsfqfDEnA0DH2gdtbED9ihVZRlU",
  authDomain: "www.misiontov.com",
  // authDomain: "misiontov-f348b.firebaseapp.com",
  projectId: "misiontov-f348b",
  signInSuccessUrl: "http://localhost:3000/main-Test-Orientacion-Vocacional",
  storageBucket: "misiontov-f348b.appspot.com",
  messagingSenderId: "709557293416",
  appId: "1:709557293416:web:298f0f1758ee19df7306dd",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MyContextProvider>
      <App />
    </MyContextProvider>
  </React.StrictMode>
);
