import { ResultPremium } from "./ResultPremium";
import { ActivatePremium } from "./ActivatePremium";
import { useMyContext } from "../../Provider/Provider";
import { useEffect, useState } from "react";
import { PropsUserData } from "../../utils/interfaces";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../..";
import { Loader } from "../Shared/Loader";

const Premium = () => {
  const { user, refresh } = useMyContext();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<PropsUserData>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (user) {
        try {
          const userRef = doc(db, "Users", user.id);
          const docSnap = await getDoc(userRef);
          docSnap.data();
          setUserData(docSnap.data() as PropsUserData);
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
      setIsLoading(false);
    })();
  }, [user, refresh]);

  return isLoading ? (
    <Loader />
  ) : userData?.isPremium ? (
    <ResultPremium />
  ) : (
    <ActivatePremium userData={userData} />
  );
};

export default Premium;
