import React from "react";
import styles from "./tovySection.module.scss";
import tovvyImg from "../../../assets/Logos/tovvyImg.png";

const TovySection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.textContainer}>
          <div className={styles.title}>Innovación y Tecnología</div>
          <div className={styles.subTitle}>
            A través de nuestras herramientas de Inteligencia Artificial y
            distintas tecnologías que componen parte de nuestros servicios,
            buscamos brindar soluciones que acompañen el analisis de nuestros
            profesionales y a su vez mejoren las estadisticas de tus resultados.
          </div>
          <div className={styles.subTitle}>
            De esta forma lograrás una investigación integral que logre
            mostrarte opciones academicas que se alineen a tus gustos,
            habilidades, deseos e intenciones.
          </div>
          <img
            src={tovvyImg}
            alt="imageHome"
            className={styles.imageHomeMobile}
          />
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={tovvyImg} alt="imageHome" className={styles.imageHome} />
        <div className={styles.textImage}>
          Tovy es nuestro asistente virtual. Te ayudará a descubrir algunos de
          los puntos clave para que encuentres tu carrera ideal.
        </div>
      </div>
    </div>
  );
};

export default TovySection;
