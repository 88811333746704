import React, { FC, useState } from "react";
import { PropsQuestions, questionsSurvey } from "../utils";
import styles from "./questionsMMYMG.module.scss";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../..";
import arrowBack from "../../../assets/Icons/arrowBack.png";
import { useMyContext } from "../../../Provider/Provider";
import { useNavigate } from "react-router-dom";
import { InfoMMYMG } from "./InfoMMYMG";
import { Loader } from "../../Shared/Loader";
interface PropsAnswersUser extends PropsQuestions {
  answerUser: boolean;
}

interface PropsQuestionsMMYMG {}
const QuestionsMMYMG: FC<PropsQuestionsMMYMG> = () => {
  const navigate = useNavigate();
  const { user, setRefresh, refresh } = useMyContext();
  const [answersUser, setAnswersUser] = useState<PropsAnswersUser[]>([]);
  const [infoMMYMGState, setInfoMMYMGState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function obtenerPrincipalesAreas(interests: PropsAnswersUser[]): string[] {
    // Crear un mapa para contar las frecuencias de cada área
    const frecuencias: { [key: string]: number } = {};

    // Contar la frecuencia de cada área
    interests.forEach((interes) => {
      frecuencias[interes.areas] = (frecuencias[interes.areas] || 0) + 1;
    });

    // Convertir el objeto de frecuencias en un arreglo de [area, frecuencia]
    // y ordenarlo en base a la frecuencia
    const areasOrdenadas = Object.entries(frecuencias).sort(
      (a, b) => b[1] - a[1]
    );

    // Seleccionar las dos áreas principales
    const principalesAreas = areasOrdenadas
      .slice(0, 2)
      .map((entrada) => entrada[0]);

    return principalesAreas;
  }
  const callResult = async (
    firstAreaMMYMG: string,
    secondAreaMMYMG: string
  ) => {
    if (user) {
      try {
        const userRef = doc(db, "Users", user.id);
        const docSnap = await getDoc(userRef);

        if (docSnap.data()) {
          await setDoc(
            userRef,
            { firstAreaMMYMG, secondAreaMMYMG },
            { merge: true }
          );
          setRefresh(!refresh);
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  const handleAnswer = async (answer: boolean) => {
    if (questionsSurvey.length - 1 === answersUser.length) {
      setIsLoading(true);
      const firstAreaMMYMG = obtenerPrincipalesAreas(answersUser)[0];
      const secondAreaMMYMG = obtenerPrincipalesAreas(answersUser)[1];
      await callResult(firstAreaMMYMG, secondAreaMMYMG);
      setIsLoading(false);
    } else {
      setAnswersUser([
        ...answersUser,
        { ...questionsSurvey[answersUser.length], answerUser: answer },
      ]);
    }
  };

  const handleBack = () => {
    setAnswersUser(answersUser.slice(0, -1));
  };

  return isLoading ? (
    <Loader />
  ) : !infoMMYMGState ? (
    <InfoMMYMG setInfoMMYMGState={setInfoMMYMGState} />
  ) : (
    questionsSurvey[answersUser.length] && (
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          <div className={styles.containerTitle}>
            <img
              src={arrowBack}
              alt="Back"
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className={styles.title}>TEST MM & MG</div>
          </div>
          <div className={styles.boxHeader}>
            {answersUser.length + 1} / {questionsSurvey.length}
          </div>
        </div>
        <div className={styles.containerBoxQuestions}>
          <div className={styles.boxQuestions}>
            <div className={styles.fontQuestions}>
              {questionsSurvey[answersUser.length].question}
            </div>
            <div className={styles.containerButtom}>
              <div
                className={styles.buttomQuestion}
                onClick={() => handleAnswer(true)}
              >
                ME INTERESA
              </div>
              <div
                className={styles.buttomQuestion}
                onClick={() => handleAnswer(false)}
              >
                NO ME INTERESA
              </div>
            </div>
          </div>
        </div>
        {answersUser.length > 0 && (
          <div className={styles.containerBack} onClick={handleBack}>
            {"<< Atras"}
          </div>
        )}
      </div>
    )
  );
};

export default QuestionsMMYMG;
