import React, { FC } from "react";
import styles from "./backButton.module.scss";
import { useNavigate } from "react-router-dom";

interface PropsBackButton {
  handleNavigate?: () => void;
}
const BackButton: FC<PropsBackButton> = ({ handleNavigate }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.buttonBack}
      onClick={() => {
        handleNavigate ? handleNavigate() : navigate(-1);
      }}
    >
      {"<< Atrás"}
    </div>
  );
};

export default BackButton;
