export interface PropsQuestion {
  id: number;
  question: string;
  dimension: string;
  dimensiones: string;
}

export const questionsSurvey: PropsQuestion[] = [
  {
    id: 1,
    question: "Soy una persona abierta a nuevas experiencias",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 2,
    question: "Suelo ser muy cuidadoso y detallista.",
    dimension: "Escrupulosidad",
    dimensiones: "C",
  },
  {
    id: 3,
    question: "A menudo siento emociones fuertes y cambiantes.",
    dimension: "Neuroticismo",
    dimensiones: "N",
  },
  {
    id: 4,
    question: "Soy amable y considerado con los demás.",
    dimension: "Amabilidad",
    dimensiones: "A",
  },
  {
    id: 5,
    question: "Suelo ser una persona muy sociable y extrovertida.",
    dimension: "Extraversión",
    dimensiones: "E",
  },
  {
    id: 6,
    question: "Suelo ser una persona muy organizada y metódica.",
    dimension: "Escrupulosidad",
    dimensiones: "C",
  },
  {
    id: 7,
    question: "Suelo ser crítico conmigo mismo y con los demás.",
    dimension: "Neuroticismo",
    dimensiones: "N",
  },
  {
    id: 8,
    question: "Soy una persona comprensiva y empática.",
    dimension: "Amabilidad",
    dimensiones: "A",
  },
  {
    id: 9,
    question: "Suelo ser una persona muy enérgica y activa.",
    dimension: "Extraversión",
    dimensiones: "E",
  },
  {
    id: 10,
    question: "Soy una persona curiosa e interesada en explorar nuevas ideas.",
    dimension: "Apertura a la experiencia",
    dimensiones: "A",
  },
  {
    id: 11,
    question: "Suelo ser una persona tranquila y serena.",
    dimension: "Neuroticismo", //inverso,
    dimensiones: "N",
  },
  {
    id: 12,
    question: "Me gusta ayudar a los demás.",
    dimension: "Amabilidad",
    dimensiones: "A",
  },
  {
    id: 13,
    question: "Me gusta estar con gente y hacer cosas con ellos.",
    dimension: "Extraversión",
    dimensiones: "E",
  },
  {
    id: 14,
    question: "Soy una persona responsable y confiable. ",
    dimension: "Escrupulosidad",
    dimensiones: "C",
  },
  {
    id: 15,
    question: "A veces me siento incómodo en situaciones sociales",
    dimension: "Extraversión", //inverso,
    dimensiones: "E",
  },
  {
    id: 16,
    question: "Suelo ser una persona muy creativa.",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 17,
    question: "Suelo ser una persona muy emocional. ",
    dimension: "Neuroticismo",
    dimensiones: "N",
  },
  {
    id: 18,
    question: "Me gusta desafiar las ideas y creencias establecidas.",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 19,
    question: "Soy una persona decidida y perseverante.",
    dimension: "Escrupulosidad",
    dimensiones: "C",
  },
  {
    id: 20,
    question:
      "A veces me cuesta trabajo comprender los sentimientos de los demás",
    dimension: "Amabilidad", //inverso,
    dimensiones: "A",
  },
  {
    id: 21,
    question: "Me gusta estar solo y hacer cosas por mi cuenta.",
    dimension: "Extraversión", //inverso,
    dimensiones: "E",
  },
  {
    id: 22,
    question: "Me gusta experimentar cosas nuevas y diferentes. ",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 23,
    question: "Suelo ser una persona muy amistosa y afectuosa.",
    dimension: "Amabilidad",
    dimensiones: "A",
  },
  {
    id: 24,
    question: "Suelo ser una persona muy optimista y positiva.",
    dimension: "Neuroticismo", //inverso,
    dimensiones: "N",
  },
  {
    id: 25,
    question: "Me gusta liderar y tomar decisiones. ",
    dimension: "Extraversión",
    dimensiones: "E",
  },
  {
    id: 26,
    question: "Soy una persona confiada y segura de mí misma.",
    dimension: "Neuroticismo", //inverso
    dimensiones: "N",
  },
  {
    id: 27,
    question: "Suelo ser una persona muy analítica y reflexiva.",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 28,
    question: "Me gusta ser el centro de atención. ",
    dimension: "Extraversión",
    dimensiones: "E",
  },
  {
    id: 29,
    question: "Soy una persona relajada y tranquila.",
    dimension: "Neuroticismo", //inverso,
    dimensiones: "N",
  },
  {
    id: 30,
    question: "Me gusta trabajar duro para lograr mis objetivos.",
    dimension: "Escrupulosidad",
    dimensiones: "C",
  },
  {
    id: 31,
    question: "A veces me preocupo demasiado por las cosas. ",
    dimension: "Neuroticismo",
    dimensiones: "N",
  },
  {
    id: 32,
    question: "Me gusta ayudar a los demás a alcanzar sus objetivos.",
    dimension: "Amabilidad",
    dimensiones: "A",
  },
  {
    id: 33,
    question: "Suelo ser una persona muy comunicativa y expresiva.",
    dimension: "Extraversión",
    dimensiones: "E",
  },
  {
    id: 34,
    question:
      "Soy una persona perfeccionista y exigente conmigo mismo y con los demás.",
    dimension: "Escrupulosidad",
    dimensiones: "C",
  },
  {
    id: 35,
    question: "A veces me cuesta trabajo relacionarme con los demás. ",
    dimension: "Amabilidad", //inverso,
    dimensiones: "A",
  },
  {
    id: 36,
    question: "Me gusta salir y divertirme con mis amigos.",
    dimension: "Extraversión",
    dimensiones: "E",
  },
  {
    id: 37,
    question: "Suelo ser una persona muy imaginativa y fantasiosa.",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 38,
    question: "Soy una persona muy sentimental y emocional.",
    dimension: "Neuroticismo",
    dimensiones: "N",
  },
  {
    id: 39,
    question: "Me gusta experimentar cosas nuevas y emocionantes. ",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 40,
    question: "Soy una persona muy compasiva y solidaria.",
    dimension: "Amabilidad",
    dimensiones: "A",
  },
  {
    id: 41,
    question: "Me gusta estar en situaciones desafiantes y competitivas.",
    dimension: "Extraversión",
    dimensiones: "C",
  },
  {
    id: 42,
    question: "Suelo ser una persona muy filosófica y reflexiva.",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 43,
    question: "Soy una persona muy sensible y tierna.",
    dimension: "Neuroticismo",
    dimensiones: "N",
  },
  {
    id: 44,
    question:
      "Me gusta aprender cosas nuevas y adquirir nuevos conocimientos. ",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 45,
    question:
      "Soy una persona muy cooperativa y dispuesta a trabajar en equipo.",
    dimension: "Amabilidad",
    dimensiones: "A",
  },
  {
    id: 46,
    question: "Me gusta hablar en público y dar discursos.",
    dimension: "Extraversión",
    dimensiones: "E",
  },
  {
    id: 47,
    question: "Suelo ser muy crítico y exigente con los demás.",
    dimension: "Neuroticismo",
    dimensiones: "N",
  },
  {
    id: 48,
    question: "Soy una persona muy optimista y confiada en el futuro.",
    dimension: "Neuroticismo", //inverso,
    dimensiones: "N",
  },
  {
    id: 49,
    question:
      "Me gusta estar en contacto con la naturaleza y disfrutar de actividades al aire libre. ",
    dimension: "Apertura a la experiencia",
    dimensiones: "O",
  },
  {
    id: 50,
    question: "Soy una persona muy organizada y planificada.",
    dimension: "Escrupulosidad",
    dimensiones: "C",
  },
];
