import styles from "./headerHome.module.scss";
import mainLogo from "../../../assets/Logos/mainLogo.png";
import { useMyContext } from "../../../Provider/Provider";
import { useNavigate } from "react-router-dom";
import { MenuProfile } from "./MenuProfile";
import { useState } from "react";

const HeaderHome = () => {
  const { user, handleLogin } = useMyContext();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className={styles.container}>
      <img
        onClick={() => {
          navigate("/");
        }}
        className={styles.logo}
        src={mainLogo}
        alt="Test de orientacion Vocacional"
      />
      {user ? (
        <div className={styles.containerImgProfile}>
          <img
            src={user?.photoProfile || "default-profile-image-url"}
            alt="Profile"
            className={styles.imgProfile}
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          />
          {openMenu && <MenuProfile setOpenMenu={setOpenMenu} />}
        </div>
      ) : (
        <div className={styles.botonLogin} onClick={handleLogin}>
          Acceder
        </div>
      )}
    </div>
  );
};

export default HeaderHome;
