import React, { FC } from "react";
import styles from "./resultPremium.module.scss";
import { useMyContext } from "../../../Provider/Provider";
import { useNavigate } from "react-router-dom";

interface PropsResultPremium {
  status?: string;
}
const ResultPremium: FC<PropsResultPremium> = ({ status }) => {
  const { user } = useMyContext();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <img
        src={user?.photoProfile || "default-profile-image-url"}
        alt="Profile"
        className={styles.imageProfile}
      />
      <div className={styles.containterTitle}>
        <div className={styles.title}>
          {user?.isActiveAccount
            ? `¡FELICITACIONES ${user?.firstName}!`
            : `¡Estás a un Paso, ${user?.firstName}!`}
        </div>
        <div className={styles.subTitle}>
          {user?.isActiveAccount
            ? `HAS ACTIVADO TU MEMBRESÍA PREMIUM`
            : `Tu Membresía Premium está casi lista`}
        </div>
      </div>
      <div className={styles.description}>
        {user?.isActiveAccount
          ? `   Disfrutá de todas las posibilidades que te brinda Misión TOV
        Premium.Todos nuestros test se encuentran disponibles, corré ahora y
        descubrí tu futuro. No olvides coordinar tu entrevista al finalizar los
        cuestionarios y explorá todas las recomendaciones que Tovy tiene para`
          : `Un miembro de nuestro equipo se pondrá en contacto contigo para completar la activación de tu cuenta premium. Queremos asegurarnos de que tu experiencia sea excelente desde el principio.
          Por favor, mantente atenta a tus mensajes de WhatsApp y a tu correo electrónico para recibir nuestra comunicación.
        Si tienes algún problema o necesitas más información, no dudes en escribirnos a nuestro correo de soporte: empresatov@gmail.com.

`}
      </div>
      <div
        className={styles.buttomHome}
        onClick={() => navigate("/main-Test-Orientacion-Vocacional")}
      >
        {user?.isActiveAccount
          ? `      Volver al inicio`
          : `Esperar Confirmación`}
      </div>
    </div>
  );
};

export default ResultPremium;
