import React from "react";
import styles from "./footerHome.module.scss";
import logoTov from "../../../assets/Logos/logoTov.png";
import logoInstagram from "../../../assets/Logos/logoInstagram.png";
import logoFacebook from "../../../assets/Logos/logoFacebook.png";
const FooterHome = () => {
  const handleRedirect = () => {
    window.open("https://www.instagram.com/misiontov/", "_blank");
  };
  const handleRedirectFace = () => {
    window.open(
      "https://www.facebook.com/profile.php?id=61565651284385",
      "_blank"
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <img src={logoTov} alt="logo" />
          <div>
            <div className={styles.textTerms}> Terminos y condiciones</div>
            <div className={styles.textTerms}> Politicas de privacidad</div>
          </div>
        </div>
        <div className={styles.contactContainer}>
          <div className={styles.infoContainer}>
            <div className={styles.textContact}>Contact Us</div>
            <div className={styles.textEmail}> +54 9 11 57739345</div>
            <div className={styles.textEmail}>empresatov@gmail.com</div>
          </div>
          <div className={styles.containerLogo}>
            <img
              src={logoFacebook}
              alt="facebook"
              onClick={handleRedirectFace}
            />

            <img src={logoInstagram} alt="Instagram" onClick={handleRedirect} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterHome;
