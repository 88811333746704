import React, { FC } from "react";
import styles from "./menuProfile.module.scss";
import cross from "../../../../assets/components/header/cross.png";
import { useMyContext } from "../../../../Provider/Provider";
import { useNavigate } from "react-router-dom";
interface PropsMenu {
  setOpenMenu: (e: boolean) => void;
}

const MenuProfile: FC<PropsMenu> = ({ setOpenMenu }) => {
  const { user, handleSignOut } = useMyContext();
  const navigate = useNavigate();
  return (
    <div className={styles.mask} onClick={() => setOpenMenu(false)}>
      <div
        className={styles.container}
        onClick={(e) => {
          e.isPropagationStopped();
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className={styles.containerCross}>
          <img
            src={cross}
            alt="cross"
            onClick={(e) => {
              setOpenMenu(false);
            }}
          />
        </div>
        <img
          src={user?.photoProfile || "default-profile-image-url"}
          alt="Profile"
          className={styles.imgProfile}
        />
        <div className={styles.greeting}>¡Hola {user?.firstName}!</div>
        {/* <div className={styles.buttonMenu}>Mi cuenta</div> */}
        <div
          className={styles.buttonMenu}
          onClick={() => {
            setOpenMenu(false);
            navigate("/testResults");
          }}
        >
          Resultados
        </div>
        <div
          className={styles.buttonMenu}
          onClick={() => {
            setOpenMenu(false);
            navigate("/Premium");
          }}
        >
          Mi plan
        </div>
        <div
          className={styles.closeSession}
          onClick={() => {
            setOpenMenu(false);
            handleSignOut();
          }}
        >
          Cerrar Sesión
        </div>
      </div>
    </div>
  );
};

export default MenuProfile;
