import React, { useEffect, useState } from "react";
import styles from "./chatChaside.module.scss";
import imageChat from "../../../assets/pages/chaside/chat/imageChat.png";
import MessageList from "./MessageList/MessageList";
import InputChat from "./InputChat/InputChat";
import { API_KEY, PropsMessage, questions } from "./utils";
import axios from "axios";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../..";
import { useMyContext } from "../../../Provider/Provider";
import { BackButton } from "../../Shared/Buttons/BackButton";

const ChatChaside = () => {
  const { user, refresh, setRefresh } = useMyContext();
  const [isTyping, setIsTyping] = useState(false);
  const [contQuestion, setContQuestion] = useState(0);
  const [messages, setMessages] = useState<PropsMessage[]>([]);

  const createAnswer = async (contAnswer: number, answer: string) => {
    if (user) {
      try {
        const userRef = doc(db, "Users", user.id);
        const docSnap = await getDoc(userRef);
        if (docSnap.data()) {
          const questionField = `question${contAnswer}`;
          if (contAnswer === 10) {
            await setDoc(userRef, { [questionField]: answer }, { merge: true });
            setRefresh(!refresh);
          } else {
            await setDoc(userRef, { [questionField]: answer }, { merge: true });
          }
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  const functionUnderstoodIsFalse = async (userMessage: string) => {
    const propmtsAlumn = [
      {
        role: "user",
        content: `Vos preguntaste esto: ${questions[contQuestion - 1].question}.
        La otra persona respondio esto: ${userMessage}
        Explicale a esta persona que queria decir la pregunta, dales sugerencias de como contestar tu pregunta, porque contesto mal y ejemplos,todo esto en menos de 100 palabras`,
      },
    ];

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
        },
      }
    );

    setMessages([
      ...messages,
      {
        message: userMessage,
        sender: "sender",
      },
      {
        message: response.data.choices[0].message.content,
        sender: "tovy",
      },
    ]);
  };
  const handleSendAlumn = async (userMessage: string) => {
    setIsTyping(true);
    const messegeAlumn = {
      message: userMessage,
      sender: "sender",
    };
    setMessages([...messages, messegeAlumn]);
    const propmtsAlumn = [
      {
        role: "user",
        content: `Sos un psicologo haciendo un Test Vocacional a un persona. Anteriormente le preguntaste: ${
          questions[contQuestion - 1].question
        } y la respuesta de la persona fue :${userMessage}. Si la persona no entendio la pregunta y te respondio otra cosa o no es concreto, dame como respuesta : understood[NO] y si entendio la pregunta y respondio con coherencia devolveme : understood[SI]. `,
      },
    ];

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );
      if (
        response.data.choices[0].message.content.includes("understood[NO]") ||
        response.data.choices[0].message.content.includes("understood [NO]")
      ) {
        await functionUnderstoodIsFalse(userMessage);
      } else {
        await createAnswer(contQuestion, userMessage);
        setMessages([
          ...messages,
          messegeAlumn,
          {
            message: questions[contQuestion].question,
            sender: "tovy",
          },
        ]);
        setContQuestion(contQuestion + 1);
      }
    } catch (error) {
      setMessages([
        {
          message: "Tenemos un error, por favor reintentá más tarde.",
          sender: "tovy",
        },
      ]);
    }
    setIsTyping(false);
  };

  useEffect(() => {
    (async () => {
      setIsTyping(true);
      const payload = {
        model: "gpt-4o", // Asegúrate de usar el modelo correcto disponible en ese momento
        messages: [
          {
            role: "assistant",
            content: `Yo quiero darle asistencia para un test de orientacion vocacional a un adolescente y necesito que cumplas el rol de un psicologo Virtual llamado Tovy. Preséntese en menos de 20 palabras, cree una transición a la siguiente pregunta y formule la siguiente pregunta: ${questions[0].question}`,
          },
        ],
      };

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${API_KEY}`,
            },
          }
        );
        setMessages([
          {
            message: response.data.choices[0].message.content,
            sender: "tovy",
            underStood: true,
          },
        ]);
        setContQuestion(1);
      } catch (error) {
        setMessages([
          {
            message: "Tenemos un error, por favor reintentá más tarde.",
            sender: "tovy",
            underStood: true,
          },
        ]);
      }
      setIsTyping(false);
    })();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.borderContainer}>
        <div className={styles.containerImg}>
          <div className={styles.profileText}>
            <div className={styles.title}>HOLA</div>
            <div className={styles.name}>ME LLAMO TOVY</div>
          </div>
          <img src={imageChat} alt="img" className={styles.imgStyles} />
        </div>
        <div className={styles.containerChatBox}>
          <MessageList isTyping={isTyping} messages={messages} />
          <InputChat
            handleSendAlumn={handleSendAlumn}
            isTyping={isTyping}
          ></InputChat>
        </div>
      </div>

      <div className={styles.containerBack}>
        <BackButton />
      </div>
    </div>
  );
};

export default ChatChaside;
