import styles from "./titleBack.module.scss";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/Icons/arrowBack.png";
import { useLocation } from "react-router-dom";
interface ProsContaienrProfile {
  title: string;
}
const TitleBack = ({ title }: ProsContaienrProfile) => {
  const navigate = useNavigate();
  let location = useLocation();

  return (
    <div className={styles.containerProfile}>
      <div className={styles.profileText}>
        <div className={styles.containerTitle}>
          {location.pathname !== "/main-Test-Orientacion-Vocacional" ? (
            <img
              src={arrowBack}
              alt="Back"
              onClick={() => {
                navigate(-1);
              }}
            />
          ) : (
            <></>
          )}
          <div className={styles.title}>{title}</div>
        </div>
      </div>
    </div>
  );
};

export default TitleBack;
