import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./inputChat.module.scss";
import arrowSendMessege from "../../../../assets/pages/chaside/chat/arrowSendMessege.png";
interface PropsInputChat {
  handleSendAlumn: (e: string) => Promise<void>;
  isTyping: boolean;
}
const InputChat: FC<PropsInputChat> = ({ handleSendAlumn, isTyping }) => {
  const [answerInput, setAnswerInput] = useState("");
  const [textareaHeight, setTextareaHeight] = useState("46px");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    setTextareaHeight("auto");
    if (textareaRef.current) {
      setTextareaHeight(
        `${
          textareaRef.current.scrollHeight < 56
            ? 46
            : textareaRef.current.scrollHeight
        }px`
      );
    }
    if (!answerInput) {
      setTextareaHeight(`46px`);
    }
  }, [answerInput]);

  const manejarKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && answerInput) {
      setAnswerInput("");
      setTextareaHeight(`46px`);
      await handleSendAlumn(answerInput);
      setAnswerInput("");
    }
  };
  return (
    <div className={styles.containerInput}>
      <textarea
        ref={textareaRef}
        placeholder="Escribe tu respuesta..."
        style={{ height: textareaHeight }}
        value={answerInput}
        className={styles.inputAI}
        onKeyDown={(event) => {
          !isTyping && manejarKeyDown(event);
        }}
        onChange={(e) => {
          !isTyping && setAnswerInput(e.target.value);
        }}
      />

      <img
        src={arrowSendMessege}
        alt="send"
        className={styles.imgAI}
        onClick={async () => {
          setAnswerInput("");
          !isTyping && (await handleSendAlumn(answerInput));
        }}
      />
    </div>
  );
};

export default InputChat;
