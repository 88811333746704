import React, { useEffect, useRef, useState } from "react";
import { ContainerPorfile } from "../Shared/ContainerProfile";
import styles from "./resumeAI.module.scss";
import { useMyContext } from "../../Provider/Provider";
import { PropsChatGPTObj } from "../../utils/interfaces";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../..";
import { Loader } from "../Shared/Loader";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "./MyDocument";

const ResumeAI = () => {
  const { user } = useMyContext();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chatGptObj, setChatGptObj] = useState<PropsChatGPTObj | null>(null);

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    const handleProjectTest = async () => {
      setIsLoading(true);
      if (user) {
        try {
          const chatGptColection = doc(db, "chatGPT", user.id);
          const userRef = doc(db, "Users", user.id);
          const docSnapUser = await getDoc(userRef);
          const docSnap = await getDoc(chatGptColection);

          if (docSnap.data()) {
            setChatGptObj(docSnap.data() as PropsChatGPTObj);
            if (docSnapUser.data()) {
              await setDoc(userRef, { isCheckResume: true }, { merge: true });
            }
          }
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
      setIsLoading(false);
    };

    handleProjectTest();
  }, [user]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <ContainerPorfile title={"RESUMEN AI"} />
      <div className={styles.containerContent}>
        <div className={styles.content} ref={contentRef}>
          <div className={styles.title}> ¡FELICITACIONES!</div>

          <div className={styles.description}>
            ¡Completaste todas las etapas de test y es hora de que descargues
            tus resultados! Queda muy poco para finalizar y queremos que elijas
            aquello en lo que podrías triunfar y disfrutar, es por eso que para
            nuestra etapa final te conectamos con nuestro equipo de
            profesionales.
            <br />
            <br />
            Conocé nuestro resumen sobre tu desempeño y no olvides de agendar tu
            entrevista en el paso final. ¡Esperamos conocerte pronto!
          </div>
        </div>

        <div className={styles.containerButton}>
          {chatGptObj && (
            <PDFDownloadLink
              document={<MyDocument chatGptObj={chatGptObj} />}
              fileName={`${
                user?.firstName && capitalizeFirstLetter(user?.firstName)
              }${
                user?.secondName && capitalizeFirstLetter(user?.secondName)
              }Resumen.pdf`}
              className={styles.buttoNext}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Generando PDF..." : "Descargar informe"
              }
            </PDFDownloadLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeAI;
