import React, { FC } from "react";
import styles from "./resultMMYMG.module.scss";
import { ContainerPorfile } from "../../Shared/ContainerProfile";
import { PropsUserData } from "../../../utils/interfaces";
import { getTextoCarrera } from "../utils";
interface PropsResult {
  userData: PropsUserData;
}
const ResultMMYMG: FC<PropsResult> = ({ userData }) => {
  return (
    <div className={styles.container}>
      <ContainerPorfile title={"TEST MM Y MG"}></ContainerPorfile>
      <div className={styles.content}>
        <div className={styles.boxSkills}>
          <div className={styles.title}> 1ER ÁREA OCUPACIONAL</div>
          <div className={styles.subtitle}>
            {getTextoCarrera(userData.firstAreaMMYMG)}
          </div>
        </div>
        <div className={styles.boxSkills}>
          <div className={styles.title}> 2DA ÁREA OCUPACIONAL</div>
          <div className={styles.subtitle}>
            {getTextoCarrera(userData.secondAreaMMYMG)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultMMYMG;
