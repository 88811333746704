import React, { FC } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PropsChatGPTObj } from "../../../utils/interfaces";
import logoDocument from "../../../assets/components/document/logoDocument.png";
import imageDocoument from "../../../assets/components/document/imageDocument.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  containerHeader: {
    width: "100%",
  },

  headerTop: {
    width: "100%",
    backgroundColor: "#6EAEBB",
    height: 25,
  },
  containerLogo: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  imageLogo: { width: 64, height: 34, marginVertical: 10 },

  title: {
    fontSize: 24,
    fontStyle: "Poppins",
    textAlign: "center",
    color: "#497078",
    fontWeight: 800,
    marginBottom: 32,
  },
  subTitle: {
    fontSize: 15,
    fontStyle: "Poppins",
    textAlign: "center",
    color: "#497078",
    fontWeight: 800,
  },
  subSubTitle: {
    fontStyle: "Poppins",
    fontSize: 13,
    textAlign: "center",
    color: "#6EAEBB",
    fontWeight: 800,
  },
  containerDescription: {
    width: "100%",
    marginTop: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: 30,
  },
  description: {
    width: "80%",
    fontStyle: "Poppins",
    fontSize: 10,
    color: "#373737",
    fontWeight: 600,
  },

  containerFooter: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  containerImage: { width: "100%" },
  imageDcoument: { width: 268, height: 349 },
  containerNumber: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: 20,
  },

  section: {
    marginBottom: 10,
    padding: 10,
    backgroundColor: "#ffffff",
    borderRadius: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },

  image: {
    width: 150,
    height: 150,
    marginVertical: 10,
  },
  table: {
    width: "auto",
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    backgroundColor: "#e0e0e0",
    padding: 5,
  },
  tableCol: {
    width: "25%",
    padding: 5,
  },
  tableCell: {
    fontSize: 10,
  },

  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 20,
    right: 20,
    textAlign: "center",
  },
});

interface PropsChatGPT {
  chatGptObj?: PropsChatGPTObj;
}
const MyDocument: FC<PropsChatGPT> = ({ chatGptObj }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.containerHeader}>
            <View style={styles.headerTop}></View>
            <View style={styles.containerLogo}>
              <Image style={styles.imageLogo} src={logoDocument} />
            </View>
            <Text style={styles.title}>¡FELICITACIONES!</Text>
            <Text style={styles.subTitle}>COMPLETASTE TODOS NUESTROS TEST</Text>
            <Text style={styles.subSubTitle}>
              TU ANÁLISIS SOBRE LOS RESULTADOS
            </Text>

            <View style={styles.containerDescription}>
              <Text style={styles.description}>¡Buen trabajo!</Text>
              <Text style={styles.description}>
                Ya completaste todas nuestras instancias de ejercicios y es hora
                de que obtengas el análisis que Tovy creó para vos.Queremos
                ayudarte a encontrar las carreras que mejor encajan con tu
                perfil, tus intereses y tus talentos. Además, te brindaremos
                algunas opciones sobre dónde podrías estudiar lo que te gusta
                para que empieces a soñar en grande. En este informe, vas a ver
                un resumen de las áreas que más se destacan en tus respuestas, y
                algunas recomendaciones de carreras que podrían ser una
                excelente opción para vos. Sabemos que elegir una carrera no es
                fácil, así que nuestra idea es ofrecerte una guía clara y útil
                que te ayude a pensar en lo que verdaderamente te apasiona y en
                lo que te ves haciendo a futuro. Queremos que este análisis sea
                solo el comienzo de una etapa llena de posibilidades. No hay una
                sola respuesta correcta; lo importante es que encuentres algo
                que realmente te entusiasme. Por eso, te animamos a explorar
                cada una de las opciones con la mente abierta, considerando
                todos los caminos que podés tomar y confiando en tu intuición.
              </Text>
              <Text style={styles.description}>
                ¡No olvides agendar tu entrevista con uno de nuestros
                profesionales para completar el proceso!
              </Text>
            </View>
          </View>
          <View style={styles.containerFooter}>
            <View style={styles.containerImage}>
              <Image style={styles.imageDcoument} src={imageDocoument} />
            </View>
            <View style={styles.containerNumber}>
              <Text style={styles.subSubTitle}>1</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.containerHeader}>
            <View style={styles.headerTop}></View>
            <View style={styles.containerLogo}>
              <Image style={styles.imageLogo} src={logoDocument} />
            </View>
            <Text style={styles.subTitle}>
              RESULTADO DE 1ER CARRERA POSIBLE
            </Text>
            <Text style={styles.subSubTitle}>{chatGptObj?.firstCarrer}</Text>

            <View style={styles.containerDescription}>
              <Text style={styles.description}>
                {chatGptObj?.firstCarrerResume}
              </Text>
            </View>
          </View>
          <View style={styles.containerFooter}>
            <View style={styles.containerNumber}>
              <Text style={styles.subSubTitle}>2</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.containerHeader}>
            <View style={styles.headerTop}></View>
            <View style={styles.containerLogo}>
              <Image style={styles.imageLogo} src={logoDocument} />
            </View>
            <Text style={styles.subTitle}>
              LUGARES CONVENIENTES DONDE ESTUDIAR
            </Text>
            <Text style={styles.subSubTitle}>{chatGptObj?.firstCarrer}</Text>

            <View style={styles.containerDescription}>
              <Text style={styles.description}>
                {chatGptObj?.firstCarrerLocation}
              </Text>
            </View>
          </View>
          <View style={styles.containerFooter}>
            <View style={styles.containerNumber}>
              <Text style={styles.subSubTitle}>3</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.containerHeader}>
            <View style={styles.headerTop}></View>
            <View style={styles.containerLogo}>
              <Image style={styles.imageLogo} src={logoDocument} />
            </View>
            <Text style={styles.subTitle}>
              RESULTADO DE 2DA CARRERA POSIBLE
            </Text>
            <Text style={styles.subSubTitle}>{chatGptObj?.secondCarrer}</Text>

            <View style={styles.containerDescription}>
              <Text style={styles.description}>
                {chatGptObj?.secondCarrerResume}
              </Text>
            </View>
          </View>
          <View style={styles.containerFooter}>
            <View style={styles.containerNumber}>
              <Text style={styles.subSubTitle}>4</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.containerHeader}>
            <View style={styles.headerTop}></View>
            <View style={styles.containerLogo}>
              <Image style={styles.imageLogo} src={logoDocument} />
            </View>
            <Text style={styles.subTitle}>
              LUGARES CONVENIENTES DONDE ESTUDIAR
            </Text>
            <Text style={styles.subSubTitle}>{chatGptObj?.secondCarrer}</Text>

            <View style={styles.containerDescription}>
              <Text style={styles.description}>
                {chatGptObj?.secondCarrerLocation}
              </Text>
            </View>
          </View>
          <View style={styles.containerFooter}>
            <View style={styles.containerNumber}>
              <Text style={styles.subSubTitle}>5</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.containerHeader}>
            <View style={styles.headerTop}></View>
            <View style={styles.containerLogo}>
              <Image style={styles.imageLogo} src={logoDocument} />
            </View>
            <Text style={styles.subTitle}>
              RESULTADO DE 3ER CARRERA POSIBLE
            </Text>
            <Text style={styles.subSubTitle}>{chatGptObj?.thirdCarrer}</Text>

            <View style={styles.containerDescription}>
              <Text style={styles.description}>
                {chatGptObj?.thirdCarrerResume}
              </Text>
            </View>
          </View>
          <View style={styles.containerFooter}>
            <View style={styles.containerNumber}>
              <Text style={styles.subSubTitle}>6</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.containerHeader}>
            <View style={styles.headerTop}></View>
            <View style={styles.containerLogo}>
              <Image style={styles.imageLogo} src={logoDocument} />
            </View>
            <Text style={styles.subTitle}>
              LUGARES CONVENIENTES DONDE ESTUDIAR
            </Text>
            <Text style={styles.subSubTitle}>{chatGptObj?.thirdCarrer}</Text>

            <View style={styles.containerDescription}>
              <Text style={styles.description}>
                {chatGptObj?.thirdCarrerLocation}
              </Text>
            </View>
          </View>
          <View style={styles.containerFooter}>
            <View style={styles.containerNumber}>
              <Text style={styles.subSubTitle}>7</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
