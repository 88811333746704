import React, { FC } from "react";
import styles from "./resultCincoGrandes.module.scss";
import { ContainerPorfile } from "../../Shared/ContainerProfile";
import { PropsUserData } from "../../../utils/interfaces";
interface PropsResult {
  userData: PropsUserData;
}
const ResultCincoGrandes: FC<PropsResult> = ({ userData }) => {
  return (
    <div className={styles.container}>
      <ContainerPorfile title={"TEST CINCO GRANDES"} />
      <div className={styles.content}>
        <div className={styles.boxSkills}>
          <div className={styles.containerTitle}>
            <div className={styles.title}> Apertura a la experiencia</div>

            <div className={styles.description}>
              Buscar nuevas experiencias y habilidades intelectuales. Son
              originales e imaginativos, curiosos por el medio externo e
              interno, interesados por ideas nuevas y valores no convencionales.
            </div>
          </div>
          <div className={styles.porcent}>{userData.O}%</div>
        </div>
        <div className={styles.boxSkills}>
          <div className={styles.containerTitle}>
            <div className={styles.title}> Extroversión</div>

            <div className={styles.description}>
              Pronunciado compromiso o unión con el mundo externo, ser muy
              sociables, asertivas, habladoras y necesitan constante
              estimulación (sensaciones nuevas).
            </div>
          </div>
          <div className={styles.porcent}>{userData.E}%</div>
        </div>
        <div className={styles.boxSkills}>
          <div className={styles.containerTitle}>
            <div className={styles.title}> Amabilidad</div>

            <div className={styles.description}>
              Ser cordiales, amables, cooperativas, compasivas, altruistas,
              consideradas, confiadas y solidarias.
            </div>
          </div>
          <div className={styles.porcent}>{userData.A}%</div>
        </div>
        <div className={styles.boxSkills}>
          <div className={styles.containerTitle}>
            <div className={styles.title}> Neuroticismo</div>

            <div className={styles.description}>
              Inestabilidad emocional, ansiedad, preocupación, baja autoestima,
              baja tolerancia al estrés, poca sociabilidad.
            </div>
          </div>
          <div className={styles.porcent}>{userData.N}%</div>
        </div>
        <div className={styles.boxSkills}>
          <div className={styles.containerTitle}>
            <div className={styles.title}> Escrupulosidad</div>

            <div className={styles.description}>
              Autocontrol, tanto en sus impulsos como también en la
              planificación, organización y ejecución de tareas. Está asociada
              además con la responsabilidad, confiabilidad, puntualidad,
              honestidad y escrupulosidad.
            </div>
          </div>
          <div className={styles.porcent}>{userData.C}%</div>
        </div>
      </div>
    </div>
  );
};

export default ResultCincoGrandes;
