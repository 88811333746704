import React, { FC } from "react";
import styles from "./infoCincoGrandes.module.scss";
import arrowBack from "../../../../assets/Icons/arrowBack.png";
import { useNavigate } from "react-router-dom";
interface PropsInfoCincoGrandes {
  setIsInfo: (e: boolean) => void;
}

const InfoCincoGrandes: FC<PropsInfoCincoGrandes> = ({ setIsInfo }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>
        <div className={styles.containerTitle}>
          <img
            src={arrowBack}
            alt="Back"
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className={styles.title}>TEST 5 GRANDES</div>
        </div>
        <div className={styles.boxHeader}>SOBRE LA EVALUACIÓN</div>
      </div>
      <div className={styles.textInfo}>
        Este primer test consta de un total de 98 preguntas que deberán ser
        respondidas con “SI” o con “NO” dependiendo como te veas frente a
        diversas situaciones. Una vez seleccionada la respuesta, las preguntas
        se irán pasando automáticamente. A pesar de que parezca que son muchas,
        el test es muy sencillo y lleva solo unos pocos minutos. Con esta
        herramienta lograremos ayudarte a conocer cuál sería la decisión más
        acertada partiendo de tus intereses, habilidades y/o preferencias,
        conocerás cual es el área ocupacional más acertada con tus elecciones.
      </div>
      <div className={styles.containerButton}>
        <div
          className={styles.buttomQuestion}
          onClick={() => {
            setIsInfo(true);
          }}
        >
          Entendido
        </div>
      </div>
    </div>
  );
};

export default InfoCincoGrandes;
