import styles from "./mercadoPagoPayment.module.scss";
import axios from "axios";
import { ContainerPorfile } from "../../../Shared/ContainerProfile";
import tovvy from "../../../../assets/pages/premium/tovvy.png";
import check from "../../../../assets/Icons/check.png";
import logoMP from "../../../../assets/pages/premium/logoMP.png";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { useMyContext } from "../../../../Provider/Provider";
import { useState } from "react";
import { Loader } from "../../../Shared/Loader";
initMercadoPago("APP_USR-39736748-c2cc-46f4-a38d-efc355ebbd47");

//COMPRADOR
// TESTUSER2136636080
// crohSfBcdT
// 5031 7557 3453 0604

// 123
// 11/25

//VENDEDOR
//TESTUSER790833622
//W00TsU2nAs

const MercadoPagoPayment = () => {
  const url = new URL(window.location.href);
  const { user } = useMyContext();
  const [isLoading, setIsLoading] = useState(false);
  const productData = {
    title: "Hamburguesa",
    quantity: 1,
    price: 1000,
    userId: user?.id,
    url: url.origin,
  };
  const createPreference = async () => {
    setIsLoading(true);
    const urlBack = window.location.href.includes("localhost")
      ? "http://localhost:4000"
      : "https://servertov.onrender.com";
    try {
      const response = await axios.post(
        `${urlBack}/api/mercadoPago/createPreference`,
        productData
      );
      const { redirectUrl } = response.data;
      return redirectUrl;
    } catch (error) {
      alert("error");
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleBuy = async () => {
    const url = await createPreference();
    if (url) window.location.href = url;
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <ContainerPorfile title={"MI PLAN"} />
      <div className={styles.content}>
        <div className={styles.contentText}>
          <div className={styles.text}>
            A través de nuestras herramientas de Inteligencia Artificial y
            distintas tecnologías que componen parte de nuestros servicios,
            buscamos brindar soluciones que acompañen el analisis de nuestros
            profesionales y a su vez mejoren las estadisticas de tus resultados.
            <br />
            <br />
            De esta forma lograrás una investigación integral que logre
            mostrarte opciones academicas que se alineen a tus gustos,
            habilidades, deseos e intenciones.
          </div>
        </div>
        <div className={styles.contentBox}>
          <div className={styles.card}>
            <div className={styles.titleCard}> Premium</div>
            <div className={styles.containerCheck}>
              <img src={check} alt="check" />
              <div className={styles.textCard}>Perfil con tus avances</div>
            </div>
            <div className={styles.containerCheck}>
              <img src={check} alt="check" />
              <div className={styles.textCard}>
                Acceso a todos los test disponibles
              </div>
            </div>
            <div className={styles.containerCheck}>
              <img src={check} alt="check" />
              <div className={styles.textCard}>Blog informativo</div>
            </div>
            <div className={styles.containerCheck}>
              <img src={check} alt="check" />
              <div className={styles.textCard}>Resultados y análisis</div>
            </div>
            <div className={styles.containerCheck}>
              <img src={check} alt="check" />
              <div className={styles.textCard}>
                Entrevista Online personalizada
              </div>
            </div>
            <img src={tovvy} alt="tovu" className={styles.butonStart}></img>
          </div>
        </div>
      </div>
      <div className={styles.containerFooter}>
        <img src={logoMP} alt="" />
        <div className={styles.textFooter}>
          Usted será redirigido a la plataforma de pago electrónico “Mercado
          Pago” ajena a TOV.{" "}
        </div>

        <div className={styles.buttonFooter} onClick={handleBuy}>
          Pagar
        </div>
      </div>
    </div>
  );
};

export default MercadoPagoPayment;
