import React, { useEffect, useState } from "react";
import styles from "./testResults.module.scss";
import { ContainerPorfile } from "../Shared/ContainerProfile";
import { totalResult } from "./utils";
import { PropsUserData } from "../../utils/interfaces";
import { doc, getDoc } from "firebase/firestore";
import { useMyContext } from "../../Provider/Provider";
import { useNavigate } from "react-router-dom";
import { db } from "../..";
import { Loader } from "../Shared/Loader";

const TestResults = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<PropsUserData>();
  const { user } = useMyContext();
  const navigate = useNavigate();

  const isTestEnable = (test: string) => {
    if (test === "TEST CHASIDE") {
      return userData?.areaMasRepetidaHabilidades ? true : false;
    }
    if (test === "TEST MM & MG") {
      return userData?.firstAreaMMYMG ? true : false;
    }

    if (test === "TEST 5 GRANDES") {
      return userData?.A ? true : false;
    }
    if (test === "Pre-resumen") {
      return userData?.resumeAiTitle ? true : false;
    }
    if (test === "ENTREVISTA") {
      return false;
    }

    return false;
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (user) {
        try {
          const userRef = doc(db, "Users", user.id);
          const docSnap = await getDoc(userRef);

          if (docSnap.data()) {
            setUserData(docSnap.data() as PropsUserData);
          }
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
      setIsLoading(false);
    })();
  }, [user]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <div className={styles.containerHeader}>
        <ContainerPorfile title={"TUS RESULTADOS"} />
      </div>
      <div className={styles.containerContent}>
        {totalResult.map((e) => (
          <div
            className={styles.boxSkills}
            style={
              isTestEnable(e.title)
                ? {}
                : { opacity: 0.5, cursor: "not-allowed" }
            }
          >
            <div className={styles.title}> {e.title}</div>

            <div className={styles.description}>{e.description}</div>
            <div
              className={styles.button}
              style={isTestEnable(e.title) ? {} : { cursor: "not-allowed" }}
              onClick={() => {
                isTestEnable(e.title) && navigate(e.route);
              }}
            >
              Ver resultado
            </div>
          </div>
        ))}
      </div>
      {/* <div className={styles.containerButton}>
        <div
          className={
            userData?.interview ? styles.buttoNext : styles.buttonNotNext
          }
          onClick={() => {
            !userData?.interview && navigate("/resumeAI");
          }}
        >
          Obtené tu resumen
        </div>
      </div> */}
    </div>
  );
};

export default TestResults;
