import React from "react";
import styles from "./secondSection.module.scss";
import { ProgresBar } from "./ProgresBar";
import useIsMobile from "../../../hooks/useIsMobile";

const SecondSection = () => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <div className={styles.containerMobile}>
      <div className={styles.title}> Nuestro proceso</div>
      <div className={styles.description}>
        Contamos con herramientas diseñadas para ayudarte a a explorar y
        comprender sus intereses, habilidades y valores, con el objeto de tomar
        decisiones informadas sobre su carrera profesional.
      </div>
      <ProgresBar />
      <div
        className={styles.botonTest}
        onClick={() => {
          // navigate("/informarcion-Test-Orientacion-Vocacional");
        }}
      >
        Comenzá ahora
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.title}> Test de Orientacion Vocacional</div>
      <span>
        Un test de orientación vocacional es una herramienta diseñada para
        ayudar a individuos a explorar y comprender sus intereses, habilidades y
        valores, con el objetivo de tomar decisiones informadas sobre su carrera
        profesional. Este examen evalúa preferencias personales, aptitudes y
        motivaciones para ofrecer recomendaciones sobre posibles opciones
        vocacionales alineadas con las características del individuo.
      </span>

      <ProgresBar />
    </div>
  );
};

export default SecondSection;
