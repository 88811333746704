export const totalResult = [
  {
    title: "TEST CHASIDE",
    description: "Conocé tus intereses y habilidades",
    route: "/test-chaside",
  },
  {
    title: "TEST MM & MG",
    description: "¿Cuáles son tus áreas ocupacionales?",
    route: "/test-MM-y-MG",
  },
  {
    title: "TEST 5 GRANDES",
    description: "Conocé tus intereses y habilidades",
    route: "/test-cinco-grandes",
  },
  {
    title: "Pre-resumen",
    description: "Un breve resumen para que te conozcas un poco mas.",
    route: "/resumeAi",
  },
  {
    title: "ENTREVISTA",
    description: "Descubrí como se conforma tu 100%",
    route: "/calendar",
  },
];
