import React from "react";
import { Main } from "../../components/Main";
import { useMyContext } from "../../Provider/Provider";
import { Navigate } from "react-router-dom";

const MainPage = () => {
  const { user } = useMyContext();
  if (!user) {
    return <Navigate to="/" replace />;
  }
  return <Main />;
};

export default MainPage;
