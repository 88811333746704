import React, { useEffect, useState } from "react";
import styles from "./panelTeacher.module.scss";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../..";
import { PropsChatGPTObj, PropsUserData } from "../../utils/interfaces";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "../ResumeAI/MyDocument";

const PanelTeacher = () => {
  const [userTeacher, setUserTeacher] = useState<PropsUserData[] | null>(null);
  useEffect(() => {
    const getUsersAndSports = async () => {
      try {
        const usersCollection = collection(db, "Users");
        const usersSnapshot = await getDocs(usersCollection);
        const usersList: PropsUserData[] = usersSnapshot.docs.map((doc) => ({
          ...(doc.data() as PropsUserData),
        }));

        for (let user of usersList) {
          const deportesCollection = collection(db, "chatGPT");
          const q = query(deportesCollection, where("id", "==", user.id));
          const deportesSnapshot = await getDocs(q);
          const objChatGPTList =
            deportesSnapshot.docs[0]?.data() as PropsChatGPTObj;
          user.objChatGPT = objChatGPTList;
        }

        usersList && setUserTeacher(usersList);
        return usersList;
      } catch (error) {
        console.error("Error al obtener usuarios y deportes:", error);
      }
    };

    getUsersAndSports();
  }, []);
  return (
    <div className={styles.container}>
      {userTeacher?.map((user) => (
        <div className={styles.rowClient}>
          <div>
            {user.firstName} {user.secondName}
          </div>
          <div>
            {user.objChatGPT && (
              <PDFDownloadLink
                document={<MyDocument chatGptObj={user.objChatGPT} />}
                fileName={`Resumen.pdf`}
                className={styles.buttoNext}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Generando PDF..." : "Descargar informe"
                }
              </PDFDownloadLink>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PanelTeacher;
