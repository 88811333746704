import React, { FC } from "react";
import styles from "./resultChaside.module.scss";
import { ContainerPorfile } from "../../Shared/ContainerProfile";
import { PropsUserData } from "../../../utils/interfaces";
import {
  getCarrers,
  getDescriptionSkills,
  getDescriptionInterests,
} from "./utils";

interface PropsResult {
  userData: PropsUserData;
}
const ResultChaside: FC<PropsResult> = ({ userData }) => {
  return (
    <div className={styles.container}>
      <ContainerPorfile title={"TEST CHASIDE"}></ContainerPorfile>
      <div className={styles.content}>
        <div className={styles.boxSkills}>
          <div className={styles.title}> HABILIDADES</div>
          <div className={styles.subtitle}>
            {userData.areaMasRepetidaHabilidades &&
              getCarrers(userData.areaMasRepetidaHabilidades)}
          </div>
          <div className={styles.description}>
            {userData.areaMasRepetidaHabilidades &&
              getDescriptionSkills(userData.areaMasRepetidaHabilidades)}
          </div>
        </div>
        <div className={styles.boxSkills}>
          <div className={styles.title}> INTERESES</div>
          <div className={styles.subtitle}>
            {userData.areaMasRepetidaIntereses &&
              getCarrers(userData.areaMasRepetidaIntereses)}
          </div>
          <div className={styles.description}>
            {userData.areaMasRepetidaIntereses &&
              getDescriptionInterests(userData.areaMasRepetidaIntereses)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultChaside;
