import React, { FC, useState } from "react";
import styles from "./questionsChaside.module.scss";
import arrowBack from "../../../assets/Icons/arrowBack.png";
import { PropsQuestions, questionsSurvey } from "./utils";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../..";
import { useMyContext } from "../../../Provider/Provider";
import { useNavigate } from "react-router-dom";
import { InfoChaside } from "./InfoChaside";
import { Loader } from "../../Shared/Loader";
interface PropsAnswersUser extends PropsQuestions {
  answerUser: boolean;
}
interface PropsQuestionsChaside {}
const QuestionsChaside: FC<PropsQuestionsChaside> = () => {
  const navigate = useNavigate();
  const { user, setRefresh, refresh } = useMyContext();
  const [answersUser, setAnswersUser] = useState<PropsAnswersUser[]>([]);
  const [infoChaside, setInfoChaside] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const calcularAreaMasRepetida = (resultado: "intereses" | "habilidades") => {
    const areasFiltradas = answersUser.filter(
      (item) => item.resultado === resultado
    );
    const conteoAreas: { [key: string]: number } = areasFiltradas.reduce(
      (acc: any, item) => {
        acc[item.area] = (acc[item.area] || 0) + 1;
        return acc;
      },
      {}
    );

    let areaMasRepetida: string = "";
    let maxConteo = 0;
    for (const area in conteoAreas) {
      if (conteoAreas[area] > maxConteo) {
        areaMasRepetida = area;
        maxConteo = conteoAreas[area];
      }
    }

    return areaMasRepetida;
  };

  const callResult = async (
    areaMasRepetidaIntereses: string,
    areaMasRepetidaHabilidades: string
  ) => {
    if (user) {
      try {
        const userRef = doc(db, "Users", user.id);
        const docSnap = await getDoc(userRef);
        if (docSnap.data()) {
          await setDoc(
            userRef,
            { areaMasRepetidaIntereses, areaMasRepetidaHabilidades },
            { merge: true }
          );
          setRefresh(!refresh);
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  const handleAnswer = async (answer: boolean) => {
    if (questionsSurvey.length - 1 === answersUser.length) {
      setIsLoading(true);
      const areaMasRepetidaIntereses = calcularAreaMasRepetida("intereses");
      const areaMasRepetidaHabilidades = calcularAreaMasRepetida("habilidades");
      await callResult(areaMasRepetidaIntereses, areaMasRepetidaHabilidades);
      setIsLoading(false);
    } else {
      console.log("else");
      setAnswersUser([
        ...answersUser,
        { ...questionsSurvey[answersUser.length], answerUser: answer },
      ]);
    }
  };

  const handleBack = () => {
    setAnswersUser(answersUser.slice(0, -1));
  };

  return isLoading ? (
    <Loader></Loader>
  ) : !infoChaside ? (
    <InfoChaside setInfoChaside={setInfoChaside} />
  ) : (
    questionsSurvey[answersUser.length] && (
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          <div className={styles.containerTitle}>
            <img
              src={arrowBack}
              alt="Back"
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className={styles.title}>TEST CHASIDE</div>
          </div>
          <div className={styles.boxHeader}>
            {answersUser.length + 1} / {questionsSurvey.length}
          </div>
        </div>
        <div className={styles.containerBoxQuestions}>
          <div className={styles.boxQuestions}>
            <div className={styles.fontQuestions}>
              {questionsSurvey[answersUser.length].question}
            </div>
            <div className={styles.containerButtom}>
              <div
                className={styles.buttomQuestion}
                onClick={() => {
                  handleAnswer(true);
                }}
              >
                SI
              </div>
              <div
                className={styles.buttomQuestion}
                onClick={() => {
                  handleAnswer(true);
                }}
              >
                NO
              </div>
            </div>
          </div>
        </div>
        {answersUser.length > 0 && (
          <div className={styles.containerBack} onClick={handleBack}>
            {"<< Atras"}
          </div>
        )}
      </div>
    )
  );
};

export default QuestionsChaside;
