import React, { ChangeEvent, useState } from "react";
import styles from "./formPremium.module.scss";
import tovyWithText from "../../../../assets/pages/premium/tovyWithText.png";
import { PropsUserForm, provinciasArgentinas } from "./utils";
import { TitleBack } from "../../../Shared/TitleBack";
import { useMyContext } from "../../../../Provider/Provider";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../..";

const FormPremium = () => {
  const { user, setRefresh, refresh } = useMyContext();
  const [userForm, setUserForm] = useState<PropsUserForm>({
    cod: "",
    name: "",
    phone: "",
    city: "",
    provincia: "Buenos Aires",
  });
  const [phoneNumber, setphoneNumber] = useState("+54");
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const numberInput = event.target.value.replace(/[^\d]/g, ""); // Eliminar caracteres no numéricos
    if (numberInput.length <= 4) {
      setUserForm({ ...userForm, cod: numberInput });
    }
  };

  function formatPhoneNumber(input: string) {
    const numberInput = input.replace(/[^\d]/g, "");
    if (numberInput.length < 3 || numberInput.slice(0, 2) !== "54") {
      return "+54";
    } else if (numberInput.length < 7) {
      const caracteristica = numberInput.slice(2, numberInput.length);
      return `+54 (${caracteristica})`;
    } else if (numberInput.length < 13) {
      const caracteristica = numberInput.slice(2, 6);
      const number = numberInput.slice(6, numberInput.length);
      return `+54 (${caracteristica}) ${number}`;
    } else {
      const caracteristica = numberInput.slice(2, 6);
      const number = numberInput.slice(6, numberInput.length - 1);
      return `+54 (${caracteristica}) ${number}`;
    }
  }

  const handlephoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setphoneNumber(formattedPhoneNumber);
  };

  const handleSubmit = async () => {
    if (user?.id) {
      try {
        const userRef = doc(db, "Users", user?.id);
        const docSnap = await getDoc(userRef);
        if (docSnap.data()) {
          await setDoc(
            userRef,
            {
              isPremium: true,
              phone: phoneNumber,
              cod: userForm.cod,
              city: userForm.city,
              provincia: userForm.provincia,
            },
            { merge: true }
          );

          setRefresh(!refresh);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const isValidFunction = () => {
    const numberPhone = phoneNumber.replace(/[^\d]/g, "");
    if (
      numberPhone.length === 12 &&
      userForm.cod.length === 4 &&
      userForm.name &&
      userForm.city
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles.container}>
      <TitleBack title="FORMULARIO" />
      <div className={styles.containerContent}>
        <div className={styles.contentText}>
          <div className={styles.text}>
            Para brindarte una experiancia aun más personalizada, requerimos una
            serie de datos que nos permitirán recomendaciones de acuerdo a zona
            y características de cada perfil.
          </div>
          <img src={tovyWithText} alt="tov" />
        </div>
        <div className={styles.contentForm}>
          <div className={styles.inputContainer}>
            <div className={styles.labelText}> Nombre Completo</div>
            <input
              type="text"
              placeholder="Nombre Completo"
              className={styles.inputLarge}
              onChange={(e) => {
                setUserForm({
                  ...userForm,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.labelText}> Provincia</div>
            <select
              name="cars"
              id="cars"
              className={styles.selectProject}
              value={userForm?.provincia}
              onChange={(e) => {
                setUserForm({
                  ...userForm,
                  provincia: e.target.value,
                });
              }}
            >
              {provinciasArgentinas.map((e, index) => (
                <option value={e} key={index}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.containerCity}>
            <div className={styles.inputContainer}>
              <div className={styles.labelText}> Ciudad</div>
              <input
                type="text"
                placeholder="Pilar"
                className={styles.inputLarge}
                onChange={(e) => {
                  setUserForm({
                    ...userForm,
                    city: e.target.value,
                  });
                }}
              />
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.labelText}> Codigo Postal</div>
              <input
                type="text"
                onChange={handleChange}
                placeholder="Ej: 2930"
                value={userForm?.cod}
                className={styles.inputLarge}
                pattern="[0-9]{4}"
                maxLength={4}
              />
            </div>
          </div>

          <div className={styles.inputContainer}>
            <div className={styles.labelText}> Celular</div>
            <input
              type="text"
              placeholder="Celular"
              onChange={handlephoneNumber}
              className={styles.inputLarge}
              value={phoneNumber}
            />
          </div>
          <div className={styles.containerButton}>
            <div
              className={
                isValidFunction()
                  ? styles.buttonFooter
                  : styles.buttonFooterBloqued
              }
              onClick={async () => {
                isValidFunction() && (await handleSubmit());
              }}
            >
              Enviar
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPremium;
