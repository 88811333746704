export const getCarrers = (typeArea: string) => {
  if (typeArea === "A") {
    return "Artísticas";
  }
  if (typeArea === "C") {
    return "Ciencias Exactas, Administrativas y Contables";
  }
  if (typeArea === "H") {
    return "Humanística y Ciencias Sociales";
  }
  if (typeArea === "S") {
    return "Ciencias de la Salud y Medicina";
  }
  if (typeArea === "I") {
    return "Ingenierías y Computación";
  }
  if (typeArea === "D") {
    return "Defensa y Seguridad";
  }
  if (typeArea === "E") {
    return "Ciencias Agrarias y Naturales";
  }
};
export const getDescriptionSkills = (typeArea: string) => {
  if (typeArea === "A") {
    return "Habilidades relacionadas con lo estético, armónico, manual, visual y auditivo. Posees aptitudes como la sensibilidad, imaginación, creatividad, detalle, innovación e intuición.";
  }
  if (typeArea === "C") {
    return "Habilidades relacionadas con la organización, supervisión, orden, análisis y síntesis, colaboración y cálculo. Posees aptitudes como la persuasión, objetividad, practicidad, tolerancia, responsabilidad y ambición.";
  }
  if (typeArea === "H") {
    return "Habilidades relacionadas con la precisión verbal, organización, relación de hechos, lingüística, orden y justicia. Posees aptitudes como la responsabilidad, justicia, conciliación, persuasión, sagacidad e imaginación.";
  }
  if (typeArea === "S") {
    return "Habilidades relacionadas con la asistencia, investigación, precisión, percepción, análisis y ayuda. Posees aptitudes como el altruismo, solidaridad, paciencia, comprensión, respeto y persuasión.";
  }
  if (typeArea === "I") {
    return "Habilidades relacionadas con el cálculo, la ciencia, lo manual, exactitud y planificación. Posees aptitudes como la precisión, practicidad, crítica, análisis y rigidez.";
  }
  if (typeArea === "D") {
    return "Habilidades relacionadas con la justicia, equidad, colaboración, espíritu de equipo y liderazgo. Posees aptitudes como la búsqueda de riesgos, solidaridad, valentía, agresividad y persuasión.";
  }
  if (typeArea === "E") {
    return "Habilidades relacionadas con la clasificación, numeración, análisis y síntesis, organización, orden e investigación. Posees aptitudes como método, análisis, observación, introversión, paciencia y seguridad.";
  }
};

export const getDescriptionInterests = (typeArea: string) => {
  if (typeArea === "A") {
    return "Intereses en lo estético, armónico, manual, visual y auditivo. Te interesan temas relacionados con la sensibilidad, imaginación, creatividad, detalle, innovación e intuición.";
  }
  if (typeArea === "C") {
    return "Intereses en la organización, supervisión, orden, análisis y síntesis, colaboración y cálculo. Te interesan temas relacionados con la persuasión, objetividad, practicidad, tolerancia, responsabilidad y ambición.";
  }
  if (typeArea === "H") {
    return "Intereses en la precisión verbal, organización, relación de hechos, lingüística, orden y justicia. Te interesan temas relacionados con la responsabilidad, justicia, conciliación, persuasión, sagacidad e imaginación.";
  }
  if (typeArea === "S") {
    return "Intereses en la asistencia, investigación, precisión, percepción, análisis y ayuda. Te interesan temas relacionados con el altruismo, solidaridad, paciencia, comprensión, respeto y persuasión.";
  }
  if (typeArea === "I") {
    return "Intereses en el cálculo, la ciencia, lo manual, exactitud y planificación. Te interesan temas relacionados con la precisión, practicidad, crítica, análisis y rigidez.";
  }
  if (typeArea === "D") {
    return "Intereses en la justicia, equidad, colaboración, espíritu de equipo y liderazgo. Te interesan temas relacionados con la búsqueda de riesgos, solidaridad, valentía, agresividad y persuasión.";
  }
  if (typeArea === "E") {
    return "Intereses en la clasificación, numeración, análisis y síntesis, organización, orden e investigación. Te interesan temas relacionados con el método, análisis, observación, introversión, paciencia y seguridad.";
  }
};
