export interface PropsQuestions {
  id: number;
  question: string;
  area: string;
  areas: string;
}

export const questionsSurvey: PropsQuestions[] = [
  {
    id: 1,
    question:
      "Diseñar programas de computación y explorar nuevas aplicaciones tecnológicas para el uso de internet.",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 2,
    question: "Criar, cuidar y tratar animales domésticos y de campo.",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 3,
    question:
      "Investigar sobre áreas verdes, medio ambiente y cambios climáticos.",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 4,
    question: "Ilustrar, dibujar y animar digitalmente ",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 5,
    question: "Seleccionar, capacitar y motivar al personal de una empresa",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 6,
    question: "Realizar excavaciones para descubrir restos del pasado",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 7,
    question: "Resolver problemas de cálculo para construir un puente",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 8,
    question:
      "Diseñar cursos para enseñar a la gente sobre temas de salud e higiene ",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 9,
    question: "Tocar un instrumento y componer música",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 10,
    question:
      "Planificar cuáles son las metas de una organización pública o privada a mediano y largo plazo",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 11,
    question:
      "Diseñar y planificar la producción masiva de artículos como muebles, autos, equipos de oficina, empaques y envases para alimentos y otros.",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 12,
    question: "Diseñar logotipos y portadas de una revista ",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 13,
    question: "Organizar eventos y atender a sus asistentes",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 14,
    question: ".Atender la salud de personas enfermas",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 15,
    question:
      "Controlar egresos e ingresos de fondos y presentar el balance final de una institución",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 16,
    question: "Hacer experimentos con plantas (Frutas, árboles, flores)",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 17,
    question: "Realizar planos para viviendas, edificios y ciudadelas ",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 18,
    question: "Investigar y probar nuevos productos farmacéuticos",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 19,
    question:
      "Hacer propuestas y formular estrategias para aprovechar las relaciones económicas entre dos países ",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 20,
    question: "Pintar, hacer escultura, ilustrar libros de arte, etc",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 21,
    question: "Elaborar campañas para introducir un nuevo producto al mercado",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 22,
    question: "Examinar y tratar los problemas visuales ",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 23,
    question:
      "Defender a clientes individuales o empresas en juicios de diferente naturaleza",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 24,
    question: "Diseñar máquinas que puedan simular actividades humanas",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 25,
    question: "Investigar las causas y efectos de trastornos emocionales",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 26,
    question: "Supervisar las ventas de un centro comercial",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 27,
    question:
      "Atender y realizar ejercicios a personas que tienen limitaciones físicas, problemas de lenguaje, etc",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 28,
    question: "Prepararse para ser modelo profesional",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 29,
    question: "Aconsejar a las personas sobre planes de ahorro e inversiones",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 30,
    question:
      "Elaborar mapas, planos e imágenes para el estudio y análisis de datos geográficos",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 31,
    question: "Diseñar juegos interactivos electrónicos para computadoras",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 32,
    question: "Realizar el control de calidad de alimentos",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 33,
    question: "Tener un negocio propio tipo comercial",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 34,
    question: "Escribir artículos periodísticos, cuentos novelas u otros",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 35,
    question: "Redactar guiones y libretos para un programa de televisión",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 36,
    question:
      "Organizar un programa de distribución y venta de un gran almacén",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 37,
    question: "Estudiar la diversidad cultural en el ámbito rural y urbano",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 38,
    question:
      "Gestionar y evaluar convenios internacionales de cooperación para el desarrollo social",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 39,
    question: "Crear campañas publicitarias",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 40,
    question:
      "Trabajar investigando la reproducción de peces, camarones y otros animales marinos",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 41,
    question: "Dedicarse a fabricar productos alimenticios de consumo masivo",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 42,
    question:
      "Gestionar y evaluar proyectos de desarrollo en una institución educativa y/o fundación",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 43,
    question:
      "Rediseñar y decorar espacios físicos en viviendas, oficinas y locales comerciales",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 44,
    question: "Administrar una empresa de turismo o agencia de viajes",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 45,
    question:
      "Aplicar métodos alternativos a la medicina tradicional para atender personas con dolencias de diversa índole",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 46,
    question: "Diseñar ropa para niños, jóvenes y adultos",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 47,
    question: "Investigar organismos vivos para elaborar vacunas",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 48,
    question:
      "Manejar y/o dar mantenimiento a dispositivos/aparatos tecnológicos en aviones, barcos, radares, etc",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 49,
    question:
      ".Estudiar idiomas extranjeros -actuales y antiguos- para hacer traducciones",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 50,
    question: "Restaurar piezas y obras de arte",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 51,
    question:
      "Revisar y dar mantenimiento a artefactos eléctricos, electrónicos y computadoras",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 52,
    question: "Enseñar a niños de 0 a 5 años",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 53,
    question: "Investigar y/o sondear nuevos mercados",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 54,
    question: "Atender la salud dental de las personas",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 55,
    question: "Tratar niños, jóvenes y adultos con problemas psicológicos",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 56,
    question:
      "Crear estrategias de promoción y venta de nuevos productos en el mercado internacional ",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 57,
    question:
      "Planificar y recomendar dietas para personas diabéticas y/o con sobrepeso",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 58,
    question:
      "Trabajar en una empresa petrolera en un cargo técnico como control de la producción",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 59,
    question: "Administrar una empresa (familiar, privada o pública)",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 60,
    question:
      "Tener un taller de reparación y mantenimiento de carros, tractores, etc.",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 61,
    question: "Ejecutar proyectos de extracción minera y metalúrgica",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 62,
    question:
      "Asistir a directivos de multinacionales con el manejo de varios idiomas",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 63,
    question: "Diseñar programas educativos para niños con discapacidad",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 64,
    question:
      "Aplicar conocimientos de estadística en investigaciones de diversas áreas (social, administrativa, salud, etc)",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 65,
    question:
      "Fotografiar hechos históricos, lugares significativos, rostros, paisajes para el área publicitaria, artística, periodística y social.",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 66,
    question: "Trabajar en museos y bibliotecas nacionales e internacionales",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 67,
    question: "Ser parte de un grupo de teatro",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 68,
    question:
      "Producir cortometrajes, spots publicitarios, programas educativos, de ficción, etc",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 69,
    question:
      ".Estudiar la influencia de las corrientes marinas y el clima y sus consecuencias ecológicas ",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CSH",
  },
  {
    id: 70,
    question:
      "Conocer las distintas religiones, su filosofía y transmitirlas a la comunidad en general",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 71,
    question:
      "Asesorar a inversionistas en la compra de bienes / acciones en mercados nacionales e internacionales",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
  {
    id: 72,
    question:
      "Estudiar grupos étnicos, sus costumbres, tradiciones, cultura y compartir sus vivencias",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 73,
    question:
      "Explorar el espacio sideral, los planetas, características y componentes ",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 74,
    question:
      "Mejorar la imagen facial y corporal de las personas aplicando diferentes técnicas",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 75,
    question: "Decorar jardines de casas y parques públicos",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 76,
    question:
      "Administrar y renovar menues de comidas de un hotel o restaurante",
    area: "Ciencias Ecológicas, Biológicas y de la Salud",
    areas: "CB",
  },
  {
    id: 77,
    question:
      "Trabajar como presentador de televisión, locutor de radio y televisión, animador de programas culturales y concursos",
    area: "Arte y Creatividad",
    areas: "AC",
  },
  {
    id: 78,
    question: ".Diseñar y ejecutar programas de turismo",
    area: "Ciencias Sociales y Humanística",
    areas: "CSH",
  },
  {
    id: 79,
    question:
      "Administrar y ordenar (planificar) adecuadamente la opción del espacio físico de ciudades, países, etc. utilizando imágenes de satélite, mapas.",
    area: "Ciencia y Tecnología",
    areas: "CT",
  },
  {
    id: 80,
    question: "Organizar, planificar y administrar centros educativos",
    area: "Económica, Administrativa y Financiera",
    areas: "EAF",
  },
];

export let msjAreaCSH = "Ciencias Sociales y Humanística";
export let msjAreaEAF = "Económica, Administrativa y Financiera";
export let msjAreaCT = "Ciencia y Tecnología";
export let msjAreaAC = "Arte y Creatividad";
export let msjAreaCB = "Ciencias Ecológicas, Biológicas y de la Salud";

export const getTextoCarrera = (letra: string | undefined) => {
  switch (letra) {
    case "CSH":
      return msjAreaCSH;
    case "EAF":
      return msjAreaEAF;
    case "CT":
      return msjAreaCT;
    case "AC":
      return msjAreaAC;
    case "CB":
      return msjAreaCB;

    default:
      return "No hay información disponible para esa letra.";
  }
};
