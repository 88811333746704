import React from "react";
import styles from "./fourthSection.module.scss";
import check from "../../../assets/Icons/check.png";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../../../Provider/Provider";

const FourthSection = () => {
  const navigate = useNavigate();
  const { user, handleLogin } = useMyContext();
  return (
    <div className={styles.container}>
      <div className={styles.overlay}></div>
      <div className={styles.title}> Nuestros Plan para vos</div>
      <div className={styles.description}>
        Conocé las posibilidades que te ofrecemos tanto en nuestra versión Free
        como en la Premium. Adaptamos tus necesidades a soluciones que brinden
        un amplio esquema de resultados. ¡Descubrí la versión con la cual queres
        comenzar!
      </div>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.titleCard}> Premium</div>
          <div className={styles.containerCheck}>
            <img src={check} alt="check" />
            <div className={styles.textCard}>Perfil con tus avances</div>
          </div>
          <div className={styles.containerCheck}>
            <img src={check} alt="check" />
            <div className={styles.textCard}>
              Acceso a todos los test disponibles
            </div>
          </div>
          <div className={styles.containerCheck}>
            <img src={check} alt="check" />
            <div className={styles.textCard}>Blog informativo</div>
          </div>
          <div className={styles.containerCheck}>
            <img src={check} alt="check" />
            <div className={styles.textCard}>Resultados y análisis</div>
          </div>
          <div className={styles.containerCheck}>
            <img src={check} alt="check" />
            <div className={styles.textCard}>
              Entrevista Online personalizada
            </div>
          </div>

          <div
            className={styles.butonStart}
            onClick={() => {
              user
                ? navigate("/main-Test-Orientacion-Vocacional")
                : handleLogin();
            }}
          >
            Comenzar
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthSection;
