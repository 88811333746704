import React, { useState } from "react";
import styles from "./questionsCincoGrandes.module.scss";
import { PropsQuestion, questionsSurvey } from "../utils";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../..";
import { useMyContext } from "../../../Provider/Provider";
import arrowBack from "../../../assets/Icons/arrowBack.png";
import { useNavigate } from "react-router-dom";
import { InfoCincoGrandes } from "./InfoCincoGrandes";
import axios from "axios";
import { Loader } from "../../Shared/Loader";
interface PropsAnswersUser extends PropsQuestion {
  answerUser: number;
}

export const QuestionsCincoGrande = () => {
  const navigate = useNavigate();
  const { user, setRefresh, refresh } = useMyContext();
  const [isInfo, setIsInfo] = useState(false);
  const [answersUser, setAnswersUser] = useState<PropsAnswersUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const createResult = async () => {
    try {
      const urlBack = window.location.href.includes("localhost")
        ? "http://localhost:4000"
        : "https://servertov.onrender.com";
      await axios.post(
        `${urlBack}/api/resumeAi/?id=${user?.id}`,
        {
          id: user?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error, "asdas");
    }
  };
  const callResult = async (porcentajes: { [k: string]: number }) => {
    if (user) {
      try {
        const userRef = doc(db, "Users", user.id);
        const docSnap = await getDoc(userRef);

        if (docSnap.data()) {
          await setDoc(userRef, porcentajes, { merge: true });
          createResult();
        }
        setRefresh(!refresh);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };
  const handleAnswerYes = async (value: number) => {
    if (questionsSurvey.length - 1 === answersUser.length) {
      setIsLoading(true);
      const total = [
        ...answersUser,
        { ...questionsSurvey[answersUser.length], answerUser: value },
      ].reduce((total, { answerUser }) => total + answerUser, 0);

      const totalPorDimension = [
        ...answersUser,
        { ...questionsSurvey[answersUser.length], answerUser: value },
      ].reduce((acumulador: any, { dimensiones, answerUser }) => {
        if (acumulador[dimensiones]) {
          acumulador[dimensiones] += answerUser;
        } else {
          acumulador[dimensiones] = answerUser;
        }
        return acumulador;
      }, {});

      const porcentajes = Object.fromEntries(
        Object.entries(totalPorDimension).map(([key, value]) => [
          key,
          Math.round(((value as number) / total) * 100),
        ])
      );
      await callResult(porcentajes);
      setIsLoading(false);
    } else {
      setAnswersUser([
        ...answersUser,
        { ...questionsSurvey[answersUser.length], answerUser: value },
      ]);
    }
  };

  const handleBack = () => {
    setAnswersUser(answersUser.slice(0, -1));
  };
  return isLoading ? (
    <Loader />
  ) : !isInfo ? (
    <InfoCincoGrandes setIsInfo={setIsInfo} />
  ) : (
    questionsSurvey[answersUser.length] && (
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          <div className={styles.containerTitle}>
            <img
              src={arrowBack}
              alt="Back"
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className={styles.title}>TEST 5 GRANDES</div>
          </div>
          <div className={styles.boxHeader}>
            {answersUser.length + 1} / {questionsSurvey.length}
          </div>
        </div>
        <div className={styles.containerBoxQuestions}>
          <div className={styles.boxQuestions}>
            <div className={styles.fontQuestions}>
              {questionsSurvey[answersUser.length].question}
            </div>
            <div className={styles.containerButtom}>
              <div
                className={styles.buttomQuestion}
                onClick={() => {
                  handleAnswerYes(1);
                }}
              >
                EN DESACUERDO
              </div>
              <div
                className={styles.buttomQuestion}
                onClick={() => {
                  handleAnswerYes(2);
                }}
              >
                LEVEMENTE EN DESACUERDO
              </div>
              <div
                className={styles.buttomQuestion}
                onClick={() => {
                  handleAnswerYes(3);
                }}
              >
                NEUTRAL
              </div>
              <div
                className={styles.buttomQuestion}
                onClick={() => {
                  handleAnswerYes(4);
                }}
              >
                LEVEMENTE DE ACUERDO
              </div>
              <div
                className={styles.buttomQuestion}
                onClick={() => {
                  handleAnswerYes(5);
                }}
              >
                DE ACUERDO
              </div>
            </div>
          </div>
        </div>
        {answersUser.length > 0 && (
          <div className={styles.containerBack} onClick={handleBack}>
            {"<< Atras"}
          </div>
        )}
      </div>
    )
  );
};
