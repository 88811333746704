export const API_KEY =
  "sk-proj-tBoBnKFDvzWhSZjP6X6PT3BlbkFJdcZqIJSwiPmSrM5d7DXE";

export interface PropsMessage {
  message: string;
  sender: string;
  underStood?: boolean;
  errorUser?: string | null;
}

export const questions = [
  {
    question: "¿Qué materias te gustan más en la escuela? ¿Por qué?",
  },
  {
    question: "¿Qué actividades disfrutas hacer en tu tiempo libre?",
  },
  {
    question:
      "¿Qué habilidades crees que tienes? (por ejemplo, habilidades artísticas, matemáticas, de comunicación, etc.)",
  },
  {
    question: "¿Te gusta trabajar en equipo o prefieres trabajar solo?",
  },
  {
    question:
      "¿Te sientes más cómodo/a trabajando en interiores o al aire libre?",
  },
  {
    question:
      "¿Te interesa ayudar a otras personas? Si es así, ¿de qué manera?",
  },
  {
    question: "¿Te gustan las ciencias y la tecnología? ¿Por qué?",
  },
  {
    question: "¿Qué tipo de trabajos has pensado hacer cuando seas adulto/a?",
  },
  {
    question: "¿Te gustaría un trabajo que implique viajar? ¿Por qué?",
  },
  {
    question: "¿Qué profesionales admiras y por qué?",
  },
];
