import React, { useEffect, useState } from "react";
import styles from "./main.module.scss";
import { useMyContext } from "../../Provider/Provider";
import arrow from "../../assets/Icons/arrow.png";
import { ProgresBar } from "../Home/SecondSection/ProgresBar";
import { useNavigate } from "react-router-dom";
import { ContainerPorfile } from "../Shared/ContainerProfile";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../..";
import { PropsUserData } from "../../utils/interfaces";
import { Loader } from "../Shared/Loader";

const Main = () => {
  const { user } = useMyContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<PropsUserData>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (user) {
        try {
          const userRef = doc(db, "Users", user.id);
          const docSnap = await getDoc(userRef);

          if (docSnap.data()) {
            setUserData(docSnap.data() as PropsUserData);
          }
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
      setIsLoading(false);
    })();
  }, [user]);

  const dinamicNavigate = () => {
    if (!userData?.isActiveAccount) {
      return navigate("/premium");
    }
    if (!userData?.areaMasRepetidaHabilidades) {
      return navigate("/test-chaside");
    }
    if (!userData?.firstAreaMMYMG) {
      return navigate("/test-MM-y-MG");
    }
    if (!userData?.A) {
      return navigate("/test-cinco-grandes");
    }
    if (!userData?.isCheckResume) {
      return navigate("/resumeAi");
    }
    if (!userData?.interview) {
      return navigate("/calendar");
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <div className={styles.container}>
        <ContainerPorfile title={"HOLA"} userData={userData} />
        <div className={styles.containerContent}>
          <div className={styles.firstColumn}>
            <div className={styles.boxAccount}>
              <div className={styles.titleAcount}>Mi cuenta </div>
              <div className={styles.plan}>
                {userData?.isPremium ? "Plan Premium" : "Plan Free"}
              </div>
              <div className={styles.school}> {user?.email}</div>
            </div>
            <div className={styles.containerBlog}>
              <div className={styles.blog} onClick={() => navigate("/Premium")}>
                Mi plan
              </div>
              <div
                className={styles.blog}
                onClick={() => navigate("/testResults")}
              >
                Resultados
              </div>
              <div
                className={styles.blog}
                onClick={() =>
                  window.open("https://guiadecarreras.siu.edu.ar/", "_blank")
                }
              >
                Buscador de carreras
              </div>
            </div>

            <div
              className={styles.boxTerms}
              onClick={() => {
                navigate("/terminos-y-condiciones");
              }}
            >
              Términos y condiciones <img src={arrow} alt="arrow"></img>
            </div>
            <div
              className={styles.boxTerms}
              onClick={() => {
                navigate("/politicas-de-privacidad");
              }}
            >
              Politicas de privacidad <img src={arrow} alt="arrow"></img>
            </div>
          </div>
          <div className={styles.secondColumn}>
            <div className={styles.containerProgres}>
              <ProgresBar userData={userData} />
              <div className={styles.buttoNext} onClick={dinamicNavigate}>
                Continuar
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Main;
