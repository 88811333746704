import styles from "./home.module.scss";
import { PrimarySection } from "./PrimarySection";
import { SecondSection } from "./SecondSection";
import { ThirdSection } from "./ThirdSection";
import { FourthSection } from "./FourthSection";
import { FooterHome } from "./FooterHome";
import { TovySection } from "./TovySection";

const Home = () => (
  <div className={styles.container}>
    <PrimarySection />
    <SecondSection />
    <ThirdSection />
    <TovySection />
    <FourthSection />
    <FooterHome />
  </div>
);

export default Home;
