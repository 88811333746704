import React, { useEffect, useState } from "react";
import styles from "./testChaside.module.scss";
import { QuestionsChaside } from "./QuestionsChaside";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../..";
import { useMyContext } from "../../Provider/Provider";
import { ResultChaside } from "./ResultChaside";
import { PropsUserData } from "../../utils/interfaces";
import { Loader } from "../Shared/Loader";
import { ChatChaside } from "./ChatChaside";

const TestChaside = () => {
  const { user, refresh } = useMyContext();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<PropsUserData>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (user) {
        try {
          const userRef = doc(db, "Users", user.id);
          const docSnap = await getDoc(userRef);
          docSnap.data();
          setUserData(docSnap.data() as PropsUserData);
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
      setIsLoading(false);
    })();
  }, [user, refresh]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      {userData?.question10 && userData.areaMasRepetidaHabilidades ? (
        <ResultChaside userData={userData} />
      ) : userData?.question10 ? (
        <QuestionsChaside />
      ) : (
        <ChatChaside />
      )}
    </div>
  );
};

export default TestChaside;
